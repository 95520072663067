import type { AsyncDataExecuteOptions } from '#app/composables/asyncData'
import type { ApplyCouponCodeMutation, CouponCodeExpiredErrorFragment, CouponCodeInvalidErrorFragment, CouponCodeLimitErrorFragment, CreateAddressInput, CreateCustomerInput, ErrorResultFragment, GuestCheckoutErrorFragment, IneligiblePaymentMethodErrorFragment, OrderFragment, OrderLineFragment, PaymentInput, RemoveOrderLineMutation, SearchProductVariantResultFragment, SetCustomerForOrderMutation, SetIntermediatePaymentMethodMutation, SetOrderShippingMethodMutation, ShippingMethodFragment, ShippingMethodQuoteFragment } from '#graphql-operations'
import type { ComputedRef, Ref } from 'vue'
import { ErrorCode } from '#graphql-operations'
import { AlreadyLoggedInError, CouponCodeExpiredError, CouponCodeInvalidError, CouponCodeLimitError, EmailAddressConflictError, GuestCheckoutError, IneligiblePaymentMethodError, IneligibleShippingMethodError, InsufficientStockError, NegativeQuantityError, NoActiveOrderError, OrderLimitError, OrderModificationError } from '~/services/errors'

interface CustomOrderStates {}
export type OrderState = 'Created' | 'Draft' | 'AddingItems' | 'ArrangingPayment' | 'PaymentAuthorized' | 'PaymentSettled' | 'PartiallyShipped' | 'Shipped' | 'PartiallyDelivered' | 'Delivered' | 'Modifying' | 'ArrangingAdditionalPayment' | 'Cancelled' | keyof CustomOrderStates

export default defineNuxtPlugin(async (nuxtApp) => {
  if (nuxtApp.payload.error)
    return {}

  // const { $localePath } = useNuxtApp()
  const activeOrderError = useState<ErrorResultFragment>('activeOrderError')
  const { data: activeOrder, pending: activeOrderPending, refresh: refreshActiveOrder } = await useAsyncData(
    'activeOrder',
    async () => {
      const { data } = await useGraphqlQuery('activeOrder')
      return data.activeOrder as OrderFragment
    },
    {
      lazy: true,
      server: false,
    },
  )

  const hasActiveOrder = computed(() => activeOrder.value && activeOrder.value.active && activeOrder.value.lines.length > 0)
  const cartDrawerOpen = useState<boolean>('cartDrawerOpen')

  // Order State
  const transitionOrderToState = async (nextState: OrderState) => {
    if (activeOrder.value?.state === nextState || !hasActiveOrder.value)
      return

    activeOrderPending.value = true
    const nextOrderStates = (await useGraphqlQuery('nextOrderStates')).data.nextOrderStates
    activeOrderPending.value = false

    if (nextOrderStates.includes(nextState)) {
      const result = (await useGraphqlMutation('transitionOrderToState', { state: nextState })).data.transitionOrderToState

      if (isGraphqlError(result)) {
        activeOrderError.value = result
        throw new Error(result.message)
      }

      if (isGraphqlType(result, 'Order')) {
        activeOrder.value = result
        return
      }

      throw new Error('Failed to transition order to state')
    }
  }

  // Cart
  const addItemToOrder = async (productVariant: SearchProductVariantResultFragment, quantity = 1) => {
    await transitionOrderToState('AddingItems')
    const { data } = await useGraphqlMutation('addItemToOrder', { productVariantId: productVariant.id, quantity: +quantity })

    if (isGraphqlError(data.addItemToOrder)) {
      activeOrderError.value = data.addItemToOrder
      switch (data.addItemToOrder.errorCode) {
        case ErrorCode.INSUFFICIENT_STOCK_ERROR: {
          throw new InsufficientStockError(data.addItemToOrder.message, data.addItemToOrder.quantityAvailable, data.addItemToOrder.order)
        }
        case ErrorCode.NEGATIVE_QUANTITY_ERROR: {
          throw new NegativeQuantityError(data.addItemToOrder.message)
        }
        case ErrorCode.ORDER_LIMIT_ERROR: {
          throw new OrderLimitError(data.addItemToOrder.message, data.addItemToOrder.maxItems)
        }
        case ErrorCode.ORDER_MODIFICATION_ERROR: {
          throw new OrderModificationError(data.addItemToOrder.message)
        }
        default: {
          throw new Error(data.addItemToOrder.message)
        }
      }
    }

    if (isGraphqlType(data.addItemToOrder, 'Order')) {
      activeOrder.value = data.addItemToOrder
      return data.addItemToOrder
    }

    throw new Error('Failed to add item to order')
  }

  const removeOrderLine = async (line: OrderLineFragment) => {
    await transitionOrderToState('AddingItems')
    const { data } = await useGraphqlMutation('removeOrderLine', { orderLineId: line.id }) as { data: RemoveOrderLineMutation }

    if (isGraphqlError(data.removeOrderLine)) {
      switch (data.removeOrderLine.errorCode) {
        case ErrorCode.ORDER_MODIFICATION_ERROR: {
          throw new OrderModificationError(data.removeOrderLine.message)
        }
      }
    }

    if (isGraphqlType(data.removeOrderLine, 'Order')) {
      activeOrder.value = data.removeOrderLine
      return data.removeOrderLine
    }

    throw new Error('Failed to remove item from order')
  }

  const adjustOrderLine = async (line: OrderLineFragment, quantity: number) => {
    activeOrderPending.value = true
    await transitionOrderToState('AddingItems')
    const result = (await useGraphqlMutation('adjustOrderLine', { orderLineId: line.id, quantity })).data.adjustOrderLine
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      activeOrder.value = result
      return
    }

    throw new Error('Failed to adjust order line')
  }

  // Customer
  const setCustomerForOrder = async (input: CreateCustomerInput) => {
    await transitionOrderToState('AddingItems')
    const { data } = await useGraphqlMutation('setCustomerForOrder', { input }) as { data: SetCustomerForOrderMutation }

    if (isGraphqlError(data.setCustomerForOrder)) {
      switch (data.setCustomerForOrder.errorCode) {
        case ErrorCode.ALREADY_LOGGED_IN_ERROR: {
          throw new AlreadyLoggedInError(data.setCustomerForOrder.message)
        }
        case ErrorCode.EMAIL_ADDRESS_CONFLICT_ERROR: {
          throw new EmailAddressConflictError(data.setCustomerForOrder.message)
        }
        case ErrorCode.GUEST_CHECKOUT_ERROR: {
          throw new GuestCheckoutError(data.setCustomerForOrder.message, (data.setCustomerForOrder as GuestCheckoutErrorFragment).errorDetail)
        }
        case ErrorCode.NO_ACTIVE_ORDER_ERROR: {
          throw new Error(data.setCustomerForOrder.message)
        }
        default: {
          throw new Error(data.setCustomerForOrder.message)
        }
      }
    }

    if (isGraphqlType(data.setCustomerForOrder, 'Order')) {
      activeOrder.value = data.setCustomerForOrder
      return data.setCustomerForOrder
    }

    throw new Error('Failed to set customer for order')
  }

  // Shipping
  const setOrderShippingMethod = async (shippingMethod: ShippingMethodQuoteFragment | ShippingMethodFragment) => {
    await transitionOrderToState('AddingItems')
    const { data } = await useGraphqlMutation('setOrderShippingMethod', { shippingMethodId: shippingMethod.id }) as { data: SetOrderShippingMethodMutation }

    if (isGraphqlError(data.setOrderShippingMethod)) {
      switch (data.setOrderShippingMethod.errorCode) {
        case ErrorCode.INELIGIBLE_SHIPPING_METHOD_ERROR: {
          throw new IneligibleShippingMethodError(data.setOrderShippingMethod.message)
        }
        case ErrorCode.NO_ACTIVE_ORDER_ERROR: {
          throw new NoActiveOrderError(data.setOrderShippingMethod.message)
        }
        case ErrorCode.ORDER_MODIFICATION_ERROR: {
          throw new OrderModificationError(data.setOrderShippingMethod.message)
        }
      }
    }

    if (isGraphqlType(data.setOrderShippingMethod, 'Order')) {
      activeOrder.value = data.setOrderShippingMethod
      return data.setOrderShippingMethod
    }

    throw new Error('Failed to set shipping method for order')
  }

  const setOrderShippingAddress = async (address: Partial<CreateAddressInput>) => {
    await transitionOrderToState('AddingItems')

    const { data } = await useGraphqlMutation('setOrderShippingAddress', { input: assignBlankAddressFields(address) })

    if (isGraphqlError(data.setOrderShippingAddress)) {
      switch (data.setOrderShippingAddress.errorCode) {
        case ErrorCode.NO_ACTIVE_ORDER_ERROR: {
          throw new NoActiveOrderError(data.setOrderShippingAddress.message)
        }
        default: {
          throw new Error(data.setOrderShippingAddress.message)
        }
      }
    }

    if (isGraphqlType(data.setOrderShippingAddress, 'Order')) {
      activeOrder.value = data.setOrderShippingAddress
      return data.setOrderShippingAddress
    }

    throw new Error('Failed to set shipping address for order')
  }

  const setOrderBillingAddress = async (address: Partial<CreateAddressInput>) => {
    await transitionOrderToState('AddingItems')

    const { data } = await useGraphqlMutation('setOrderBillingAddress', {
      input: assignBlankAddressFields(address),
    })

    if (isGraphqlError(data.setOrderBillingAddress)) {
      switch (data.setOrderBillingAddress.errorCode) {
        case ErrorCode.NO_ACTIVE_ORDER_ERROR: {
          throw new NoActiveOrderError(data.setOrderBillingAddress.message)
        }
        default: {
          throw new Error(data.setOrderBillingAddress.message)
        }
      }
    }

    if (isGraphqlType(data.setOrderBillingAddress, 'Order')) {
      activeOrder.value = data.setOrderBillingAddress
      return data.setOrderBillingAddress
    }

    throw new Error('Failed to set billing address for order')
  }

  const setIntermediatePaymentMethod = async (paymentMethodCode: string) => {
    if (activeOrder.value?.customFields?.intermediatePaymentMethod === paymentMethodCode)
      return

    const { data } = await useGraphqlMutation('setIntermediatePaymentMethod', { paymentMethodCode }) as { data: SetIntermediatePaymentMethodMutation }

    if (isGraphqlError(data.setIntermediatePaymentMethod)) {
      switch (data.setIntermediatePaymentMethod.errorCode) {
        case ErrorCode.INELIGIBLE_PAYMENT_METHOD_ERROR: {
          throw new IneligiblePaymentMethodError(data.setIntermediatePaymentMethod.message, (data.setIntermediatePaymentMethod as IneligiblePaymentMethodErrorFragment).eligibilityCheckerMessage)
        }
        case ErrorCode.NO_ACTIVE_ORDER_ERROR: {
          throw new NoActiveOrderError(data.setIntermediatePaymentMethod.message)
        }
        default: {
          throw new Error(data.setIntermediatePaymentMethod.message)
        }
      }
    }

    if (isGraphqlType(data.setIntermediatePaymentMethod, 'Order')) {
      activeOrder.value = data.setIntermediatePaymentMethod
      return data.setIntermediatePaymentMethod
    }

    throw new Error('Failed to set intermediate payment method for order')
  }

  const applyCouponCode = async (couponCode: string) => {
    await transitionOrderToState('AddingItems')
    const { data } = await useGraphqlMutation('applyCouponCode', { couponCode }) as { data: ApplyCouponCodeMutation }

    if (isGraphqlError(data.applyCouponCode)) {
      switch (data.applyCouponCode.errorCode) {
        case ErrorCode.COUPON_CODE_EXPIRED_ERROR: {
          throw new CouponCodeExpiredError(data.applyCouponCode.message, (data.applyCouponCode as CouponCodeExpiredErrorFragment)?.couponCode)
        }
        case ErrorCode.COUPON_CODE_INVALID_ERROR: {
          throw new CouponCodeInvalidError(data.applyCouponCode.message, (data.applyCouponCode as CouponCodeInvalidErrorFragment)?.couponCode)
        }
        case ErrorCode.COUPON_CODE_LIMIT_ERROR: {
          throw new CouponCodeLimitError(data.applyCouponCode.message, (data.applyCouponCode as CouponCodeLimitErrorFragment)?.couponCode, (data.applyCouponCode as CouponCodeLimitErrorFragment)?.limit)
        }
        default: {
          throw new Error(data.applyCouponCode.message)
        }
      }
    }

    if (isGraphqlType(data.applyCouponCode, 'Order')) {
      activeOrder.value = data.applyCouponCode
      return data.applyCouponCode
    }

    throw new Error('Failed to apply coupon code to order')
  }

  const removeCouponCode = async (couponCode: string) => {
    await transitionOrderToState('AddingItems')
    const { data } = await useGraphqlMutation('removeCouponCode', { couponCode })

    if (isGraphQLError(data.removeCouponCode)) {
      throw new Error(data.removeCouponCode.message)
    }

    if (isGraphqlType(data.removeCouponCode, 'Order')) {
      activeOrder.value = data.removeCouponCode
      return
    }

    throw new Error('Failed to remove coupon code from order')
  }

  const addPaymentToOrder = async (input: PaymentInput) => {
    activeOrderPending.value = true
    await transitionOrderToState('ArrangingPayment')
    const result = (await useGraphqlMutation('addPaymentToOrder', { input })).data.addPaymentToOrder
    activeOrderPending.value = false

    if (isGraphqlError(result)) {
      activeOrderError.value = result
      throw new Error(result.message)
    }

    if (isGraphqlType(result, 'Order')) {
      // activeOrder.value = result
      return
    }

    throw new Error('Failed to add payment to order')
  }

  const getSurchargesForOrderLine = (orderLineId: string) => {
    return activeOrder.value?.surcharges?.filter(surcharge => surcharge.orderLineId === orderLineId) || []
  }

  const orderSurcharges = computed(() => {
    return activeOrder.value?.surcharges?.filter(surcharge => surcharge.orderLineId == null) || []
  })

  const orderTotal = computed(() => {
    return activeOrder.value?.total || 0
  })

  return {
    provide: {
      activeOrder: {
        hasActiveOrder,
        cartDrawerOpen,
        activeOrder,
        activeOrderPending,
        transitionOrderToState,
        refreshActiveOrder,
        addItemToOrder,
        removeOrderLine,
        adjustOrderLine,
        setCustomerForOrder,
        setOrderShippingMethod,
        setOrderShippingAddress,
        setOrderBillingAddress,
        setIntermediatePaymentMethod,
        addPaymentToOrder,
        applyCouponCode,
        removeCouponCode,
        // getters
        getSurchargesForOrderLine,
        orderSurcharges,
        orderTotal,
      },
    },
  }
})

interface ActiveOrderPlugin {
  hasHasActiveOrder: ComputedRef<boolean>
  cartDrawerOpen: Ref<boolean>
  activeOrder: Ref<OrderFragment>
  activeOrderPending: Ref<boolean>
  transitionOrderToState: (nextState: OrderState) => Promise<void>
  refreshActiveOrder: (opts?: AsyncDataExecuteOptions) => Promise<void>
  addItemToOrder: (productVariant: SearchProductVariantResultFragment, quantity?: number) => Promise<void>
  removeOrderLine: (line: OrderLineFragment) => Promise<void>
  adjustOrderLine: (line: OrderLineFragment, quantity: number) => Promise<void>
  setCustomerForOrder: (customer: CreateCustomerInput) => Promise<void>
  setOrderShippingMethod: (shippingMethod: ShippingMethodQuoteFragment | ShippingMethodFragment) => Promise<void>
  setOrderShippingAddress: (address: Partial<CreateAddressInput>) => Promise<void>
  setOrderBillingAddress: (address: Partial<CreateAddressInput>) => Promise<void>
  setIntermediatePaymentMethod: (paymentMethodCode: string) => Promise<void>
  addPaymentToOrder: (input: PaymentInput) => Promise<void>
  applyCouponCode: (couponCode: string) => Promise<void>
  removeCouponCode: (couponCode: string) => Promise<void>
  getSurchargesForOrderLine: (orderLineId: string) => OrderFragment['surcharges']
  orderSurcharges: ComputedRef<OrderFragment['surcharges']>
  orderTotal: ComputedRef<number>
}

declare module '#app' {
  interface NuxtApp {
    $activeOrder: ActiveOrderPlugin
  }
  interface PageMeta {
    checkout?: boolean
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $activeOrder: ActiveOrderPlugin
  }
}
