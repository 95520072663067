import type { SearchResultFragment } from '#graphql-operations'
import { useStorage } from '@vueuse/core'

const SEVEN_DAYS_MS = 7 * 24 * 60 * 60 * 1000

export const useProductHistoryStore = defineStore('productHistory', () => {
  const { locale, locales } = useI18n()

  // state
  const historyState = useStorage<(SearchResultFragment & { ts: number })[]>(`product-history:${locale.value}`, [])

  // getters
  const history = computed(() => historyState.value)

  // actions
  const checkProductFreshness = (localeCode: string) => {
    const localeStorage = useStorage<(SearchResultFragment & { ts: number })[]>(`product-history:${localeCode}`, [])
    const now = Date.now()
    localeStorage.value = localeStorage.value.filter(item => item?.ts ? now - item.ts < SEVEN_DAYS_MS : true)
  }

  const addItemToHistory = (item: SearchResultFragment) => {
    const itemWithTimestamp = { ...item, ts: Date.now() }

    const index = historyState.value.findIndex(i => i.id === item.id)

    if (index === -1) {
      historyState.value.unshift(itemWithTimestamp)
      historyState.value = historyState.value.slice(0, 12)
    }
    else {
      historyState.value.splice(index, 1)
      historyState.value.unshift(itemWithTimestamp)
    }
  }

  const clearProductHistory = () => {
    historyState.value = []
  }

  onMounted(() => {
    locales.value.forEach((localeObj) => {
      setInterval(() => checkProductFreshness(localeObj.code), 60 * 1000)
    })

    // initial check for current locale
    checkProductFreshness(locale.value)
  })

  return {
    history,
    addItemToHistory,
    clearProductHistory,
  }
})
