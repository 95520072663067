import type { FetchOptions } from 'ofetch'
import type { Ref } from 'vue'
import type { LocaleObject } from '~/config/i18n'

export default defineNuxtPlugin({
  name: 'graphql-config',
  dependsOn: ['nuxt-graphql-middleware-provide-state'],
  setup(nuxtApp) {
    const state = useGraphqlState()
    if (!state)
      return

    const headers = useRequestHeaders(['cookie', 'baggage', 'sentry-trace'])
    const locale = ((nuxtApp.$i18n as any).locale as Ref<string>).value
    const localeObject = ((nuxtApp.$i18n as any).locales as Ref<LocaleObject[]>).value.find(l => l.code === locale)

    state.fetchOptions = {
      headers: {
        ...headers,
      },
      onRequest: (context) => {
        context.options.params ??= {}
        context.options.params.languageCode = (localeObject?.language ?? 'en').split('-')[0]
        context.options.params.currencyCode = localeObject?.currency ?? 'USD'
      },
    } satisfies FetchOptions
  },
})
