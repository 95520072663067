import { useDebounceFn, useInfiniteScroll, useResizeObserver, useScroll } from '@vueuse/core'

const INFINITE_SCROLL_CONTAINER_ID = 'infinite-scroll-container'
const INFINITE_SCROLL_ITEM_CLASS_NAME = 'infinite-scroll-item'

type LoadDirection = 'up' | 'down'

export interface UseListInfiniteScrollOptions {
  defaultTake?: number
  defaultScrollContainerId?: string
  defaultScrollItemClassName?: string
  gotoPage: (page: number) => void
  fetchPageData: (page: number, loadDirection?: LoadDirection,) => Promise<boolean>
}

export function useListInfiniteScroll({ defaultTake, defaultScrollContainerId, defaultScrollItemClassName, gotoPage, fetchPageData }: UseListInfiniteScrollOptions) {
  const route = useRoute()
  // eslint-disable-next-line unused-imports/no-unused-vars
  const router = useRouter()
  const currentPage = ref(Number.parseInt(route.query?.page as string) || 1)
  const startPage = ref(currentPage.value)
  const endPage = ref(startPage.value)
  const scrollItemHeight = ref(300)
  const itemsPerRow = ref(4)
  const scrollItemSizeInit = ref(false)
  const take = ref(defaultTake || 36)
  const total = ref(0)
  const isFetchingData = ref(false)

  const containerRef = ref<Window>(window)

  const reachBottomHandler = useDebounceFn(() => {
    fetchNextPage()
  }, 1000)

  useInfiniteScroll(
    containerRef,
    () => {
      reachBottomHandler()
    },
    {
      distance: 2000,
    },
  )

  const scrollContainerId = ref(defaultScrollContainerId ?? INFINITE_SCROLL_CONTAINER_ID)
  const scrollItemClassName = ref(defaultScrollItemClassName ?? INFINITE_SCROLL_ITEM_CLASS_NAME)

  const canLoadNextPage = computed(() => endPage.value < Math.ceil(total.value / take.value) && total.value > 0)

  const pageHeight = computed(() => scrollItemHeight.value * (take.value / itemsPerRow.value))

  const updateCurrentPage = () => {
    // allow page update only when current path is same as route path
    // i.e. scope it to only the page in which useListInfiniteScroll is used
    const allowUpdate = import.meta.client && window.location.pathname === route.path
    if (!allowUpdate) {
      return
    }
    const page
      = Math.floor(document.documentElement.scrollTop / pageHeight.value)
        + startPage.value
    if (page) {
      replaceUrlPage(String(page))
      currentPage.value = page
    }
  }

  const onResize = useDebounceFn(() => {
    try {
      const container = document.getElementById(scrollContainerId.value)
      const scrollItem = document?.body?.querySelector(
        `.${scrollItemClassName.value}`,
      )
      if (scrollItem && container) {
        scrollItemHeight.value = scrollItem.clientHeight
        itemsPerRow.value = Math.max(
          Math.floor(container.clientWidth / scrollItem.clientWidth),
          1,
        )
        scrollItemSizeInit.value = true
      }
    }
    catch (err) {
      console.warn('resize scroll item', err)
    }
  }, 1000)

  useScroll(window, { onScroll: updateCurrentPage, throttle: 1000 })
  useResizeObserver(document?.body, onResize)

  function replaceUrlPage(targetPage: string) {
    const isFirstPage = targetPage === '1'
    if (targetPage === route.query.page || (isFirstPage && !route.query.page)) {
      return
    }
    const { page, ..._restQuery } = route.query
  /*  router.replace({
      path: String(route.path),
      query: isFirstPage
        ? { ...restQuery }
        : { ...restQuery, page: targetPage },
    }).catch(console.warn) */
  }

  const reachTopHandler = useDebounceFn(() => {
    fetchPreviousPage()
  }, 1000)

  const fetchDataCallback = async (
    page: number,
    direction: LoadDirection,
    successCb: () => void,
  ) => {
    return await fetchPageData(page, direction).then((isSuccess) => {
      if (isSuccess) {
        successCb()
      }
      return isSuccess
    })
  }

  async function fetchPreviousPage() {
    if (startPage.value <= 1) {
      return
    }
    const nextPage = startPage.value - 1
    await fetchDataCallback(startPage.value - 1, 'up', () => {
      startPage.value = nextPage
      checkAfterFetchDataSuccess()
    })
  }

  async function fetchNextPage() {
    if (!canLoadNextPage.value) {
      return
    }
    const nextPage = endPage.value + 1
    await fetchDataCallback(nextPage, 'down', () => {
      endPage.value = nextPage
      checkAfterFetchDataSuccess()
      prefetchNextPage()
    })
  }

  async function prefetchNextPage() {
    updateCurrentPage()
    if (endPage.value - currentPage.value <= 1 && canLoadNextPage.value) {
      await fetchNextPage()
    }
  }

  function checkAfterFetchDataSuccess() {
    checkCurrentPageIsValid()
    checkScrollItemSize()
  }

  function checkCurrentPageIsValid() {
    const maxPage = Math.ceil(total.value / take.value)
    if (maxPage > 0 && currentPage.value > maxPage) {
      gotoPage(maxPage)
    }
  }

  function checkScrollItemSize() {
    if (scrollItemSizeInit.value) {
      return
    }
    onResize()
  }

  return {
    isFetchingData,
    fetchPreviousPage,
    fetchNextPage,
    reachTopHandler,
    prefetchNextPage,
    take,
    total,
    startPage,
    currentPage,
    endPage,
    canLoadNextPage,
    scrollItemClassName,
    scrollContainerId,
  }
}
