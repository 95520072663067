import type { OrderFragment } from '#graphql-operations'
import { ErrorCode } from '#graphql-operations'

export interface GraphQLErrorResult extends Error {
  code: ErrorCode | string
}

export class GraphQLBaseError extends Error implements GraphQLErrorResult {
  constructor(public code: ErrorCode | string, message: string) {
    super(message)
    this.name = this.constructor.name
    Error.captureStackTrace(this, this.constructor)
  }
}

/** Returned if the user authentication credentials are not valid */
export class InvalidCredentialsError extends GraphQLBaseError {
  constructor(message: string, public authenticationError: string) {
    super(ErrorCode.INVALID_CREDENTIALS_ERROR, message)
  }
}
/** Returned if `authOptions.requireVerification` is set to `true` (which is the default) and an unverified user attempts to authenticate. */
export class NotVerifiedError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.NOT_VERIFIED_ERROR, message)
  }
}

/** Returned when attempting to register or verify a customer account without a password, when one is required. */
export class MissingPasswordError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.MISSING_PASSWORD_ERROR, message)
  }
}

/** Returned when attempting an operation that relies on the NativeAuthStrategy, if that strategy is not configured. */
export class NativeAuthStrategyError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.NATIVE_AUTH_STRATEGY_ERROR, message)
  }
}

/** Returned when attempting to register or verify a customer account where the given password fails password validation. */
export class PasswordValidationError extends GraphQLBaseError {
  constructor(message: string, public validationErrorMessage: string) {
    super(ErrorCode.PASSWORD_VALIDATION_ERROR, message)
  }
}

export class VerificationTokenExpiredError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.VERIFICATION_TOKEN_EXPIRED_ERROR, message)
  }
}

export class VerificationTokenInvalidError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.VERIFICATION_TOKEN_INVALID_ERROR, message)
  }
}

export class PasswordAlreadySetError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.PASSWORD_ALREADY_SET_ERROR, message)
  }
}

// Shipping methods errors
export class IneligibleShippingMethodError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.INELIGIBLE_SHIPPING_METHOD_ERROR, message)
  }
}

export class NoActiveOrderError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.NO_ACTIVE_ORDER_ERROR, message)
  }
}

export class OrderModificationError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.ORDER_MODIFICATION_ERROR, message)
  }
}

// Update email address errors

export class EmailAddressConflictError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.EMAIL_ADDRESS_CONFLICT_ERROR, message)
  }
}

// Set customer for order errors
export class AlreadyLoggedInError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.ALREADY_LOGGED_IN_ERROR, message)
  }
}

export class GuestCheckoutError extends GraphQLBaseError {
  constructor(
    message: string,
    public errorDetail: string,
  ) {
    super(ErrorCode.GUEST_CHECKOUT_ERROR, message)
  }
}

// Adjust order line errors
export class NegativeQuantityError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.NEGATIVE_QUANTITY_ERROR, message)
  }
}

export class OrderLimitError extends GraphQLBaseError {
  constructor(
    message: string,
    public maxItems: number,
  ) {
    super(ErrorCode.ORDER_LIMIT_ERROR, message)
  }
}
export class InsufficientStockError extends GraphQLBaseError {
  constructor(
    message: string,
    public quantityAvailable: number,
    public order: OrderFragment,
  ) {
    super(ErrorCode.INSUFFICIENT_STOCK_ERROR, message)
  }
}

export class OrderStateTransitionError extends GraphQLBaseError {
  constructor(
    message: string,
    public fromState: string,
    public toState: string,
    public transitionError: string,
  ) {
    super(ErrorCode.ORDER_STATE_TRANSITION_ERROR, message)
  }
}

// Payment errors

export class IneligiblePaymentMethodError extends GraphQLBaseError {
  constructor(
    message: string,
    public eligibilityCheckerMessage?: string | null,
  ) {
    super(ErrorCode.INELIGIBLE_PAYMENT_METHOD_ERROR, message)
  }
}

export class OrderPaymentStateError extends GraphQLBaseError {
  constructor(message: string) {
    super(ErrorCode.ORDER_PAYMENT_STATE_ERROR, message)
  }
}

export class PaymentDeclinedError extends GraphQLBaseError {
  constructor(
    message: string,
    public paymentErrorMessage: string,
  ) {
    super(ErrorCode.PAYMENT_DECLINED_ERROR, message)
  }
}

export class PaymentFailedError extends GraphQLBaseError {
  constructor(
    message: string,
    public paymentErrorMessage: string,
  ) {
    super(ErrorCode.PAYMENT_DECLINED_ERROR, message)
  }
}

// Coupon code errors
export class CouponCodeExpiredError extends GraphQLBaseError {
  constructor(message: string, public couponCode: string) {
    super(ErrorCode.COUPON_CODE_EXPIRED_ERROR, message)
  }
}

export class CouponCodeInvalidError extends GraphQLBaseError {
  constructor(message: string, public couponCode: string) {
    super(ErrorCode.COUPON_CODE_INVALID_ERROR, message)
  }
}

export class CouponCodeLimitError extends GraphQLBaseError {
  constructor(message: string, public couponCode: string, public limit: number) {
    super(ErrorCode.COUPON_CODE_LIMIT_ERROR, message)
  }
}
