import type { CreateAddressInput, OrderFragment, SearchProductVariantResultFragment } from '#graphql-operations'
import { AssetType } from '#graphql-operations'
import { activeOrder, addItemToOrder } from '~/services/activeOrder'

interface CustomOrderStates {}
export type OrderState = 'Created' | 'Draft' | 'AddingItems' | 'ArrangingPayment' | 'PaymentAuthorized' | 'PaymentSettled' | 'PartiallyShipped' | 'Shipped' | 'PartiallyDelivered' | 'Delivered' | 'Modifying' | 'ArrangingAdditionalPayment' | 'Cancelled' | keyof CustomOrderStates

export const useActiveOrder = () => useNuxtApp().$activeOrder

const addressFields: Array<keyof CreateAddressInput> = ['city', 'company', 'countryCode', 'fullName', 'phoneNumber', 'province', 'streetLine1', 'streetLine2']
const addressFieldsLength = addressFields.length
export function assignBlankAddressFields(input: Partial<CreateAddressInput>) {
  for (let i = 0; i < addressFieldsLength; i++) {
    const field = addressFields[i]
    if (!(field in input) || input[field] == null)
      input[field] = ''
  }

  return input as CreateAddressInput
}

export const useOrder = defineQuery({
  key: ['activeOrder'],
  query: async () => await activeOrder(),
})

export const useAddItemToOrder = defineMutation(() => {
  const { mutate, ...mutation } = useMutation({
    mutation: async (variant: NonNullable<SearchProductVariantResultFragment> & { quantity: number }) => {
      // await transitionOrderToState('AddingItems')
      const order = await addItemToOrder(variant.id, variant.quantity)
      // eslint-disable-next-line no-console
      console.log({ order })
      return order
    },
    onMutate: async (variant) => {
      const queryCache = useQueryCache()

      // Cancel any outgoing refetches to avoid overwriting optimistic update
      queryCache.cancelQueries({ key: ['activeOrder'] })

      // Get current order from cache
      const previousOrder = queryCache.getQueryData<OrderFragment>(['activeOrder'])
      // eslint-disable-next-line no-console
      console.log({ previousOrder, variant })
      const lines: OrderFragment['lines'] = [
        ...previousOrder?.lines || [],
        {
          id: `temp-${Date.now()}`,
          unitPrice: variant.price,
          linePrice: variant.price * variant.quantity,
          quantity: variant.quantity,
          featuredAsset: {
            id: variant.featuredAsset!.id,
            preview: variant.featuredAsset!.preview,
            type: AssetType.IMAGE,
          },
          productVariant: {
            id: variant.id,
            name: variant.name,
            price: variant.price,
            options: variant.options
              ? variant.options.map(option => ({
                  id: option.id,
                  code: option.code,
                  name: option.name,
                  group: option.group,
                }))
              : [],
            product: {
              id: variant.product.id,
              slug: variant.product.slug,
              name: variant.product.name,
            },
          },
          priceBeforeDiscount: variant.customProductVariantMappings.priceBeforeDiscount!,
        },
      ]

      const totalQuantity = lines.reduce((acc, line) => acc + line.quantity, 0)

      // Perform optimistic update
      if (previousOrder) {
        const optimisticOrder: NonNullable<OrderFragment> = {
          ...previousOrder,
          lines,
          totalQuantity,
        }

        queryCache.setQueryData(['activeOrder'], optimisticOrder)
      }

      return { previousOrder }
    },
    onError: (_err, _variables, context) => {
      // Rollback on error
      const queryCache = useQueryCache()
      if (context?.previousOrder) {
        queryCache.setQueryData(['activeOrder'], context.previousOrder)
      }
    },
    onSettled: () => {
      // Invalidate to refetch fresh data
      const queryCache = useQueryCache()
      queryCache.invalidateQueries({ key: ['activeOrder'] })
    },
  })

  return { addItemToOrder: mutate, ...mutation }
})
