import type { Attributes, AttributeValue } from '@opentelemetry/api'
import { useRuntimeConfig } from '#app'
import HyperDX from '@hyperdx/browser'

const classifyRE = /(?:^|[-_])(\w)/g
const classify = (str: string): string => str.replace(classifyRE, c => c.toUpperCase()).replace(/[-_]/g, '')

const ROOT_COMPONENT_NAME = '<Root>'
const ANONYMOUS_COMPONENT_NAME = '<Anonymous>'

const repeat = (str: string, n: number): string => str.repeat(n)

export function formatComponentName(instance?: ComponentPublicInstance | null, includeFile?: boolean): string {
  if (!instance)
    return ANONYMOUS_COMPONENT_NAME

  if (instance.$root === instance)
    return ROOT_COMPONENT_NAME

  // https://github.com/getsentry/sentry-javascript/issues/5204 $options can be undefined
  if (!instance.$options)
    return ANONYMOUS_COMPONENT_NAME

  const options = instance.$options

  let name = options.name || options._componentTag || options.__name
  const file = options.__file
  if (!name && file) {
    const match = file.match(/([^/\\]+)\.vue$/)
    if (match) {
      name = match[1]
    }
  }

  return (
    (name ? `<${classify(name)}>` : ANONYMOUS_COMPONENT_NAME) + (file && includeFile !== false ? ` at ${file}` : '')
  )
}

export function generateComponentTrace(instance?: ComponentPublicInstance): string {
  if (instance && ((instance as any)._isVue || (instance as any).__isVue) && instance.$parent) {
    const tree = []
    let currentRecursiveSequence = 0
    while (instance) {
      if (tree.length > 0) {
        const last = tree[tree.length - 1] as any
        if (last.constructor === instance.constructor) {
          currentRecursiveSequence++
          instance = instance.$parent!
          continue
        }
        else if (currentRecursiveSequence > 0) {
          tree[tree.length - 1] = [last, currentRecursiveSequence]
          currentRecursiveSequence = 0
        }
      }
      tree.push(instance)
      instance = instance.$parent!
    }

    const formattedTree = tree.map((vm, i) =>
      `${(i === 0 ? '---> ' : repeat(' ', 5 + i * 2))
      + (Array.isArray(vm)
        ? `${formatComponentName(vm[0])}... (${vm[1]} recursive calls)`
        : formatComponentName(vm))}`,
    )
      .join('\n')

    return `\n\nfound in\n\n${formattedTree}`
  }

  return `\n\n(found in ${formatComponentName(instance)})`
}

const getIP = async () => $fetch<string>('https://api.ipify.org')

export default defineNuxtPlugin({
  name: 'hyperdx-client-integrations',
  async setup(nuxtApp) {
    const config = useRuntimeConfig()
    const cloudFrontViewer = useCloudFrontViewer()

    if (!config.public.hyperdx.apiKey)
      return

    const viewerAddress = cloudFrontViewer.address ?? await getIP()

    HyperDX.init({
      apiKey: useRuntimeConfig().public.hyperdx.apiKey,
      service: useRuntimeConfig().public.service.name,
      tracePropagationTargets: [window.location.origin],
      // consoleCapture: true, // Capture console logs (default false)
      advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
      maskAllInputs: false,
      maskAllText: false,
    })

    HyperDX.setGlobalAttributes({
      'viewer.address': viewerAddress,
      'environment': useRuntimeConfig().public.environment,
      'version': useRuntimeConfig().public.service.version,
      'teamName': useRuntimeConfig().public.host,
    })

    const reportNuxtError = ({ error, instance, info }: { error: unknown, instance?: ComponentPublicInstance | null, info?: string }) => {
      const componentName = formatComponentName(instance, false)
      const trace = instance ? generateComponentTrace(instance) : ''

      const attributes: Attributes = {
        componentName,
        trace,
        info,
      }

      if (instance && instance.$props) {
        // `attachProps` is enabled by default and props should only not be attached if explicitly disabled (see DEFAULT_CONFIG in `vueIntegration`).
        if (instance.$props !== false) {
          attributes.propsData = instance.$props as AttributeValue
        }
      }

      // Capture exception in the next event loop, to make sure that all breadcrumbs are recorded in time.
      setTimeout(() => {
        HyperDX.recordException(error, attributes)
      })
    }

    nuxtApp.hook('app:error', (error) => {
      reportNuxtError({ error })
    })

    nuxtApp.hook('vue:error', (error, instance, info) => {
      reportNuxtError({ error, instance, info })
    })
  },
})
