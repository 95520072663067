import { defineNuxtPlugin, useRequestHeaders } from '#imports'

const DEFAULT_VIEWER: CloudFrontViewer = {
  headerOrder: [],
}

interface CloudFrontViewer {
  userAgent?: string
  amzCfId?: string
  host?: string

  // Viewer device type flags
  /** Set to `true` when CloudFront determines that the viewer is a device with the Android operating system. */
  isAndroid?: boolean

  /** Set to `true` when CloudFront determines that the viewer is a desktop device. */
  isDesktop?: boolean

  /** Set to `true` when CloudFront determines that the viewer is a device with an Apple mobile operating system, such as iPhone, iPod touch, and some iPad devices. */
  isIOS?: boolean

  /** Set to `true` when CloudFront determines that the viewer is a mobile device. */
  isMobile?: boolean

  /** Set to `true` when CloudFront determines that the viewer is a smart TV. */
  isSmartTV?: boolean

  /** Set to `true` when CloudFront determines that the viewer is a tablet. */
  isTablet?: boolean

  // Viewer location information
  /** Contains the IP address of the viewer and the source port of the request. */
  address?: string

  /** Contains the autonomous system number (ASN) of the viewer. */
  ASN?: string

  /** Contains the two-letter country code for the viewer's country. For a list of country codes, see @see [ISO 3166-1 alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2). */
  country?: string

  /** Contains the name of the viewer's city. */
  city?: string

  /** Contains the name of the viewer's country. */
  countryName?: string

  /** Contains a code (up to three characters) that represent the viewer's region. The region is the first-level subdivision (the broadest or least specific) of the @see [ISO 3166-2](https://en.wikipedia.org/wiki/ISO_3166-2) code. */
  countryRegion?: string

  /** Contains the name of the viewer's region. The region is the first-level subdivision (the broadest or least specific) of the @see [ISO 3166-2](https://en.wikipedia.org/wiki/ISO_3166-2) code. */
  countryRegionName?: string

  /** Contains the viewer's approximate latitude. */
  latitude?: string

  /** Contains the viewer's approximate longitude. */
  longitude?: string

  /** Contains the viewer's metro code. This is present only when the viewer is in the United States. */
  metroCode?: string

  /** Contains the viewer's postal code. */
  postalCode?: string

  /** Contains the viewer's time zone, in @see [IANA time zone database format](https://en.wikipedia.org/wiki/List_of_tz_database_time_zones) (for example, `America/Los_Angeles`). */
  timeZone?: string

  // Viewers header structure
  /** Contains the viewer's header names in the order requested, separated by a colon. For example: `CloudFront-Viewer-Header-Order: Host:User-Agent:Accept:Accept-Encoding`. Headers beyond the character limit of 7,680 are truncated. */
  headerOrder: string[]

  /** Contains the total number of the viewer's headers. */
  headerCount?: number

  // TLS related headers
  /** Contains the @see [JA3 fingerprint](https://github.com/salesforce/ja3) of the viewer. The JA3 fingerprint can help you determine whether the request comes from a known client, whether that's malware or a malicious bot, or an expected (allow-listed) application. */
  jA3Fingerprint?: string

  /** Contains the JA4 fingerprint of the viewer. Similar to the JA3 fingerprint, the @see [JA4 fingerprint](https://github.com/FoxIO-LLC/ja4) can help you determine whether the request comes from a known client, whether that's malware or a malicious bot, or an expected (allow-listed) application. You can use the fingerprint to build a database of known good and bad actors to apply when inspecting HTTP requests. You can then inspect the header value on your application web servers or in your @see [Lambda@Edge](https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/lambda-at-the-edge.html) and @see [CloudFront Functions](https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/cloudfront-functions.html) to compare the header value against a list of known malware fingerprints to block malicious clients. */
  jA4Fingerprint?: string

  /**
   * Contains the SSL/TLS version, the cipher, and information about the SSL/TLS handshake that was used for the connection between the viewer and CloudFront. The header value is in the following format:
   * ```
   * SSL/TLS_version:cipher:handshake_information
   * ```
   * For `handshake_information`, the header can contain the following values:
   * - `fullHandshake` – A full handshake was performed for the SSL/TLS session.
   * - `sessionResumed` – A previous SSL/TLS session was resumed.
   * - `connectionReused` – A previous SSL/TLS connection was reused.
   *
   * The following are some example values for this header:
   *
   * ```
   * TLSv1.3:TLS_AES_128_GCM_SHA256:sessionResumed
   * ```
   *
   * ```
   * TLSv1.2:ECDHE-ECDSA-AES128-GCM-SHA256:connectionReused
   * ```
   *
   * ```
   * TLSv1.1:ECDHE-RSA-AES128-SHA256:fullHandshake
   * ```
   *
   * ```
   * TLSv1:ECDHE-RSA-AES256-SHA:fullHandshake
   * ```
   *
   * For the full list of possible SSL/TLS versions and ciphers that can be in this header value, @see [Supported protocols and ciphers between viewers and CloudFront](https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/secure-connections-supported-viewer-protocols-ciphers.html).
   *
   * > **Note**
   * > - The JA3 and JA4 fingerprints are derived from the SSL/TLS Client Hello packet. They are only present for HTTPS requests.
   * > - For these TLS-related headers, you can add them to a origin request policy, but not in a cache policy.
   *
   */
  TLS?: string

  // Others
  /** Contains the original request URI that was received from the viewer. */
  errorArgs?: string

  /** Contains the original request query string parameters and values. */
  errorUri?: string

  /** Contains the protocol of the viewer's request (HTTP or HTTPS). */
  forwardedProto?: string

  /** Contains the HTTP version of the viewer's request. */
  httpVersion?: string
}

const parseBooleanHeader = (value?: string): boolean | undefined => value === 'true' ? true : value === 'false' ? false : undefined

function parseCloudFrontHeaders(headers: Record<string, string>): CloudFrontViewer {
  const viewer: CloudFrontViewer = {
    ...DEFAULT_VIEWER,
    userAgent: headers['user-agent'],
    amzCfId: headers['x-amz-cf-id'],
    host: headers.host,

    // Parse device flags
    isAndroid: parseBooleanHeader(headers['cloudfront-is-android-viewer']),
    isDesktop: parseBooleanHeader(headers['cloudfront-is-desktop-viewer']),
    isIOS: parseBooleanHeader(headers['cloudfront-is-ios-viewer']),
    isMobile: parseBooleanHeader(headers['cloudfront-is-mobile-viewer']),
    isSmartTV: parseBooleanHeader(headers['cloudfront-is-smarttv-viewer']),
    isTablet: parseBooleanHeader(headers['cloudfront-is-tablet-viewer']),

    // Parse location info
    address: headers['cloudfront-viewer-address'],
    ASN: headers['cloudfront-viewer-asn'],
    country: headers['cloudfront-viewer-country'],
    city: headers['cloudfront-viewer-city'],
    countryName: headers['cloudfront-viewer-country-name'],
    countryRegion: headers['cloudfront-viewer-country-region'],
    countryRegionName: headers['cloudfront-viewer-country-region-name'],
    latitude: headers['cloudfront-viewer-latitude'],
    longitude: headers['cloudfront-viewer-longitude'],
    metroCode: headers['cloudfront-viewer-metro-code'],
    postalCode: headers['cloudfront-viewer-postal-code'],
    timeZone: headers['cloudfront-viewer-time-zone'],

    // Parse header structure
    headerOrder: headers['cloudfront-viewer-header-order']?.split(':').map(header => header.trim()) || [],
    headerCount: headers['cloudfront-viewer-header-count'] ? +headers['cloudfront-viewer-header-count'] : undefined,

    // Parse TLS info
    jA3Fingerprint: headers['cloudfront-viewer-ja3-fingerprint'],
    jA4Fingerprint: headers['cloudfront-viewer-ja4-fingerprint'],
    TLS: headers['cloudfront-viewer-tls'],

    // Parse request info
    errorArgs: headers['cloudfront-error-args'],
    errorUri: headers['cloudfront-error-uri'],
    forwardedProto: headers['cloudfront-forwarded-proto'],
    httpVersion: headers['cloudfront-http-version'],
  }

  return viewer
}

export default defineNuxtPlugin({
  name: 'cloudfront-viewer',
  setup() {
    const cloudFrontViewer = useState<CloudFrontViewer>(() => DEFAULT_VIEWER)

    if (import.meta.server) {
      const headers = useRequestHeaders()
      cloudFrontViewer.value = parseCloudFrontHeaders(headers)
    }

    return {
      provide: {
        cloudFrontViewer,
      },
    }
  },
})

declare module '#app' {
  interface NuxtApp {
    $cloudFrontViewer: CloudFrontViewer
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $cloudFrontViewer: CloudFrontViewer
  }
}
