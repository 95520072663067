import type { ActiveOrderQuery, AddItemToOrderMutation, AddPaymentToOrderMutation, AdjustOrderLineMutation, ApplyCouponCodeMutation, CouponCodeExpiredErrorFragment, CouponCodeInvalidErrorFragment, CouponCodeLimitErrorFragment, CreateAddressInput, CreateCustomerInput, GuestCheckoutErrorFragment, IneligiblePaymentMethodErrorFragment, InsufficientStockErrorFragment, NextOrderStatesQuery, OrderFragment, OrderLimitErrorFragment, OrderStateTransitionErrorFragment, PaymentDeclinedErrorFragment, PaymentFailedErrorFragment, PaymentInput, RemoveCouponCodeMutation, RemoveOrderLineMutation, SetCustomerForOrderMutation, SetIntermediatePaymentMethodMutation, SetOrderBillingAddressMutation, SetOrderShippingAddressMutation, SetOrderShippingMethodMutation, TransitionOrderToStateMutation } from '#graphql-operations'
import { ErrorCode } from '#graphql-operations'
import { AlreadyLoggedInError, CouponCodeExpiredError, CouponCodeInvalidError, CouponCodeLimitError, EmailAddressConflictError, GuestCheckoutError, IneligiblePaymentMethodError, IneligibleShippingMethodError, InsufficientStockError, NegativeQuantityError, NoActiveOrderError, OrderLimitError, OrderModificationError, OrderPaymentStateError, OrderStateTransitionError, PaymentDeclinedError, PaymentFailedError } from './errors'

interface CustomOrderStates {}
export type OrderState = 'Created' | 'Draft' | 'AddingItems' | 'ArrangingPayment' | 'PaymentAuthorized' | 'PaymentSettled' | 'PartiallyShipped' | 'Shipped' | 'PartiallyDelivered' | 'Delivered' | 'Modifying' | 'ArrangingAdditionalPayment' | 'Cancelled' | keyof CustomOrderStates

export async function activeOrder() {
  const { data } = await useGraphqlQuery('activeOrder') as { data: ActiveOrderQuery }
  return data.activeOrder
}

export async function nextOrderStates(): Promise<OrderState[]> {
  const result = await useGraphqlQuery('nextOrderStates') as { data: NextOrderStatesQuery }
  return result.data.nextOrderStates as OrderState[]
}

export async function transitionOrderToState(state: OrderState): Promise<OrderFragment> {
  const states = await nextOrderStates()

  if (states.includes(state)) {
    const { data } = await useGraphqlMutation('transitionOrderToState', { state: state as string }) as { data: TransitionOrderToStateMutation }

    if (isGraphqlError(data.transitionOrderToState)) {
      switch (data.transitionOrderToState.errorCode) {
        case ErrorCode.ORDER_STATE_TRANSITION_ERROR: {
          throw new OrderStateTransitionError(data.transitionOrderToState.message, data.transitionOrderToState.fromState, data.transitionOrderToState.toState, data.transitionOrderToState.transitionError)
        }
        default: {
          throw new Error(data.transitionOrderToState.message)
        }
      }
    }

    if (isGraphqlType(data.transitionOrderToState, 'Order'))
      return data.transitionOrderToState as OrderFragment

    throw new Error('Failed to transition order to state')
  }

  throw new Error(`Invalid order state transition to ${state as string}, valid states are: ${states.join(', ')}`)
}

export async function addItemToOrder(productVariantId: string, quantity: number): Promise<OrderFragment> {
  await transitionOrderToState('AddingItems')
  const { data } = await useGraphqlMutation('addItemToOrder', { productVariantId, quantity }) as { data: AddItemToOrderMutation }

  if (isGraphqlError(data.addItemToOrder)) {
    switch (data.addItemToOrder.errorCode) {
      case ErrorCode.INSUFFICIENT_STOCK_ERROR: {
        throw new InsufficientStockError(data.addItemToOrder.message, (data.addItemToOrder as InsufficientStockErrorFragment).quantityAvailable, (data.addItemToOrder as InsufficientStockErrorFragment).order)
      }
      case ErrorCode.NEGATIVE_QUANTITY_ERROR: {
        throw new NegativeQuantityError(data.addItemToOrder.message)
      }
      case ErrorCode.ORDER_LIMIT_ERROR: {
        throw new OrderLimitError(data.addItemToOrder.message, (data.addItemToOrder as OrderLimitErrorFragment).maxItems)
      }
      case ErrorCode.ORDER_MODIFICATION_ERROR: {
        throw new OrderModificationError(data.addItemToOrder.message)
      }
      default: {
        throw new Error(data.addItemToOrder.message)
      }
    }
  }

  if (isGraphqlType(data.addItemToOrder, 'Order'))
    return data.addItemToOrder

  throw new Error('Failed to add item to order')
}

export async function removeOrderLine(orderLineId: string): Promise<OrderFragment> {
  await transitionOrderToState('AddingItems')
  const { data } = await useGraphqlMutation('removeOrderLine', { orderLineId }) as { data: RemoveOrderLineMutation }

  if (isGraphqlError(data.removeOrderLine)) {
    switch (data.removeOrderLine.errorCode) {
      case ErrorCode.ORDER_MODIFICATION_ERROR: {
        throw new OrderModificationError(data.removeOrderLine.message)
      }
    }
  }

  if (isGraphqlType(data.removeOrderLine, 'Order'))
    return data.removeOrderLine

  throw new Error('Failed to remove item from order')
}

export async function adjustOrderLine(orderLineId: string, quantity: number): Promise<OrderFragment> {
  await transitionOrderToState('AddingItems')
  const { data } = await useGraphqlMutation('adjustOrderLine', { orderLineId, quantity }) as { data: AdjustOrderLineMutation }

  if (isGraphqlError(data.adjustOrderLine)) {
    switch (data.adjustOrderLine.errorCode) {
      case ErrorCode.INSUFFICIENT_STOCK_ERROR: {
        throw new InsufficientStockError(data.adjustOrderLine.message, (data.adjustOrderLine as InsufficientStockErrorFragment)?.quantityAvailable, (data.adjustOrderLine as InsufficientStockErrorFragment)?.order)
      }
      case ErrorCode.NEGATIVE_QUANTITY_ERROR: {
        throw new NegativeQuantityError(data.adjustOrderLine.message)
      }
      case ErrorCode.ORDER_LIMIT_ERROR: {
        throw new OrderLimitError(data.adjustOrderLine.message, (data.adjustOrderLine as OrderLimitErrorFragment).maxItems)
      }
      case ErrorCode.ORDER_MODIFICATION_ERROR: {
        throw new OrderModificationError(data.adjustOrderLine.message)
      }
      default: {
        throw new Error(data.adjustOrderLine.message)
      }
    }
  }

  if (isGraphqlType(data.adjustOrderLine, 'Order'))
    return data.adjustOrderLine

  throw new Error('Failed to adjust order line')
}

export async function setCustomerForOrder(input: CreateCustomerInput): Promise<OrderFragment> {
  await transitionOrderToState('AddingItems')
  const { data } = await useGraphqlMutation('setCustomerForOrder', { input }) as { data: SetCustomerForOrderMutation }

  if (isGraphqlError(data.setCustomerForOrder)) {
    switch (data.setCustomerForOrder.errorCode) {
      case ErrorCode.ALREADY_LOGGED_IN_ERROR: {
        throw new AlreadyLoggedInError(data.setCustomerForOrder.message)
      }
      case ErrorCode.EMAIL_ADDRESS_CONFLICT_ERROR: {
        throw new EmailAddressConflictError(data.setCustomerForOrder.message)
      }
      case ErrorCode.GUEST_CHECKOUT_ERROR: {
        throw new GuestCheckoutError(data.setCustomerForOrder.message, (data.setCustomerForOrder as GuestCheckoutErrorFragment).errorDetail)
      }
      case ErrorCode.NO_ACTIVE_ORDER_ERROR: {
        throw new Error(data.setCustomerForOrder.message)
      }
      default: {
        throw new Error(data.setCustomerForOrder.message)
      }
    }
  }

  if (isGraphqlType(data.setCustomerForOrder, 'Order'))
    return data.setCustomerForOrder

  throw new Error('Failed to set customer for order')
}

export async function setOrderShippingMethod(shippingMethodId: string): Promise<OrderFragment> {
  await transitionOrderToState('AddingItems')
  const { data } = await useGraphqlMutation('setOrderShippingMethod', { shippingMethodId }) as { data: SetOrderShippingMethodMutation }

  if (isGraphqlError(data.setOrderShippingMethod)) {
    switch (data.setOrderShippingMethod.errorCode) {
      case ErrorCode.INELIGIBLE_SHIPPING_METHOD_ERROR: {
        throw new IneligibleShippingMethodError(data.setOrderShippingMethod.message)
      }
      case ErrorCode.NO_ACTIVE_ORDER_ERROR: {
        throw new NoActiveOrderError(data.setOrderShippingMethod.message)
      }
      case ErrorCode.ORDER_MODIFICATION_ERROR: {
        throw new OrderModificationError(data.setOrderShippingMethod.message)
      }
    }
  }

  if (isGraphqlType(data.setOrderShippingMethod, 'Order'))
    return data.setOrderShippingMethod

  throw new Error('Failed to set shipping method for order')
}

export async function setOrderShippingAddress(input: CreateAddressInput) {
  await transitionOrderToState('AddingItems')

  const { data } = await useGraphqlMutation('setOrderShippingAddress', { input }) as { data: SetOrderShippingAddressMutation }

  if (isGraphqlError(data.setOrderShippingAddress)) {
    switch (data.setOrderShippingAddress.errorCode) {
      case ErrorCode.NO_ACTIVE_ORDER_ERROR: {
        throw new NoActiveOrderError(data.setOrderShippingAddress.message)
      }
      default: {
        throw new Error(data.setOrderShippingAddress.message)
      }
    }
  }

  if (isGraphqlType(data.setOrderShippingAddress, 'Order'))
    return data.setOrderShippingAddress

  throw new Error('Failed to set shipping address for order')
}

export async function setOrderBillingAddress(input: CreateAddressInput) {
  await transitionOrderToState('AddingItems')

  const { data } = await useGraphqlMutation('setOrderBillingAddress', { input }) as { data: SetOrderBillingAddressMutation }

  if (isGraphqlError(data.setOrderBillingAddress)) {
    switch (data.setOrderBillingAddress.errorCode) {
      case ErrorCode.NO_ACTIVE_ORDER_ERROR: {
        throw new NoActiveOrderError(data.setOrderBillingAddress.message)
      }
      default: {
        throw new Error(data.setOrderBillingAddress.message)
      }
    }
  }

  if (isGraphqlType(data.setOrderBillingAddress, 'Order'))
    return data.setOrderBillingAddress

  throw new Error('Failed to set billing address for order')
}

export async function setIntermediatePaymentMethod(paymentMethodCode: string): Promise<OrderFragment> {
  const { data } = await useGraphqlMutation('setIntermediatePaymentMethod', { paymentMethodCode }) as { data: SetIntermediatePaymentMethodMutation }

  if (isGraphqlError(data.setIntermediatePaymentMethod)) {
    switch (data.setIntermediatePaymentMethod.errorCode) {
      case ErrorCode.INELIGIBLE_PAYMENT_METHOD_ERROR: {
        throw new IneligiblePaymentMethodError(data.setIntermediatePaymentMethod.message, (data.setIntermediatePaymentMethod as IneligiblePaymentMethodErrorFragment).eligibilityCheckerMessage)
      }
      case ErrorCode.NO_ACTIVE_ORDER_ERROR: {
        throw new NoActiveOrderError(data.setIntermediatePaymentMethod.message)
      }
      default: {
        throw new Error(data.setIntermediatePaymentMethod.message)
      }
    }
  }

  if (isGraphqlType(data.setIntermediatePaymentMethod, 'Order')) {
    return data.setIntermediatePaymentMethod
  }

  throw new Error('Failed to set intermediate payment method for order')
}

export async function applyCouponCode(couponCode: string): Promise<OrderFragment> {
  await transitionOrderToState('AddingItems')
  const { data } = await useGraphqlMutation('applyCouponCode', { couponCode }) as { data: ApplyCouponCodeMutation }

  if (isGraphqlError(data.applyCouponCode)) {
    switch (data.applyCouponCode.errorCode) {
      case ErrorCode.COUPON_CODE_EXPIRED_ERROR: {
        throw new CouponCodeExpiredError(data.applyCouponCode.message, (data.applyCouponCode as CouponCodeExpiredErrorFragment)?.couponCode)
      }
      case ErrorCode.COUPON_CODE_INVALID_ERROR: {
        throw new CouponCodeInvalidError(data.applyCouponCode.message, (data.applyCouponCode as CouponCodeInvalidErrorFragment)?.couponCode)
      }
      case ErrorCode.COUPON_CODE_LIMIT_ERROR: {
        throw new CouponCodeLimitError(data.applyCouponCode.message, (data.applyCouponCode as CouponCodeLimitErrorFragment)?.couponCode, (data.applyCouponCode as CouponCodeLimitErrorFragment)?.limit)
      }
      default: {
        throw new Error(data.applyCouponCode.message)
      }
    }
  }

  if (isGraphqlType(data.applyCouponCode, 'Order')) {
    return data.applyCouponCode
  }

  throw new Error('Failed to apply coupon code to order')
}

export async function removeCouponCode(couponCode: string) {
  await transitionOrderToState('AddingItems')
  const { data } = await useGraphqlMutation('removeCouponCode', { couponCode }) as { data: RemoveCouponCodeMutation }

  if (isGraphQLError(data.removeCouponCode))
    throw new Error((data.removeCouponCode as any)?.message)

  if (isGraphqlType(data.removeCouponCode, 'Order'))
    return data.removeCouponCode

  throw new Error('Failed to remove coupon code from order')
}

export async function addPaymentToOrder(input: PaymentInput): Promise<OrderFragment> {
  await transitionOrderToState('ArrangingPayment')
  const { data } = await useGraphqlMutation('addPaymentToOrder', { input }) as { data: AddPaymentToOrderMutation }

  if (isGraphqlError(data.addPaymentToOrder)) {
    switch (data.addPaymentToOrder.errorCode) {
      case ErrorCode.INELIGIBLE_PAYMENT_METHOD_ERROR: {
        throw new IneligiblePaymentMethodError(data.addPaymentToOrder.message, (data.addPaymentToOrder as IneligiblePaymentMethodErrorFragment).eligibilityCheckerMessage)
      }
      case ErrorCode.NO_ACTIVE_ORDER_ERROR: {
        throw new NoActiveOrderError(data.addPaymentToOrder.message)
      }
      case ErrorCode.ORDER_PAYMENT_STATE_ERROR: {
        throw new OrderPaymentStateError(data.addPaymentToOrder.message)
      }
      case ErrorCode.ORDER_STATE_TRANSITION_ERROR: {
        throw new OrderStateTransitionError(data.addPaymentToOrder.message, (data.addPaymentToOrder as OrderStateTransitionErrorFragment).fromState, (data.addPaymentToOrder as OrderStateTransitionErrorFragment).toState, (data.addPaymentToOrder as OrderStateTransitionErrorFragment).transitionError)
      }
      case ErrorCode.PAYMENT_DECLINED_ERROR: {
        throw new PaymentDeclinedError(data.addPaymentToOrder.message, (data.addPaymentToOrder as PaymentDeclinedErrorFragment).paymentErrorMessage)
      }
      case ErrorCode.PAYMENT_FAILED_ERROR: {
        throw new PaymentFailedError(data.addPaymentToOrder.message, (data.addPaymentToOrder as PaymentFailedErrorFragment).paymentErrorMessage)
      }
      default: {
        throw new Error(data.addPaymentToOrder.message)
      }
    }
  }

  if (isGraphqlType(data.addPaymentToOrder, 'Order')) {
    return data.addPaymentToOrder
  }

  throw new Error('Failed to add payment to order')
}
