<script setup lang="ts">
const props = withDefaults(defineProps<{
  columns?: number
  multiple?: boolean
  open?: string | string[]
}>(), {
  columns: 4,
  multiple: true,
  open: [] as any,
})

const emit = defineEmits<{
  (event: 'change', args: string[]): void
}>()

const isOpen = ref<string[]>(props.open ?? [])
const items = ref<string[]>([])

function toggle(payload: string) {
  if (!props.multiple)
    isOpen.value = [payload]
  else if (isOpen.value.includes(payload))
    isOpen.value = isOpen.value.filter(item => item !== payload)
  else
    isOpen.value = [...isOpen.value, payload]

  emit('change', isOpen.value)
}

const isColumnOpen = (title: string) => isOpen.value.includes(title)
const addFooterItem = (title: string) => items.value.push(title)

provide('footerContextKey', { isColumnOpen, toggle, addFooterItem, items })
</script>

<template>
  <footer aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">
      Footer
    </h2>

    <div class="xl:grid xl:grid-cols-3 xl:gap-8">
      <div
        class="flex flex-1 flex-col md:grid md:gap-8"
        :style="{ 'grid-template-columns': `repeat(${columns}, minmax(0, 1fr))`, 'grid-column': `span ${columns} / span ${columns}` }"
      >
        <slot name="columns" />
      </div>
    </div>

    <slot v-if="$slots.subfooter" name="subfooter" />
  </footer>
</template>
