import type { CurrencyCode } from '#graphql-operations'
import type { NuxtOptions } from '@nuxt/schema'

type ExtractLocaleType<T> = T extends { locales?: (infer L)[] } ? L : never

type ExtractLocaleObject<T> = ExtractLocaleType<T> extends (string | { code: string })
  ? Extract<ExtractLocaleType<T>, { code: string }>
  : never

export type LocaleObject = ExtractLocaleObject<NuxtOptions['i18n']>

export interface I18nLocaleObject extends LocaleObject {
  nativeName: string
  country: string
  currency: CurrencyCode
}

export const i18n: Omit<NuxtOptions['i18n'], 'locales'> & { locales: I18nLocaleObject[] } = {
  locales: [
    {
      code: 'cs',
      language: 'cs-CZ',
      name: 'Czech',
      nativeName: 'Čeština',
      dir: 'ltr',
      file: 'cs.json',
      country: 'CZ',
      currency: 'CZK' as CurrencyCode.CZK,
      flag: 'circle-flags:cz',
    },
    {
      code: 'pl',
      language: 'pl-PL',
      name: 'Polish',
      nativeName: 'Polski',
      dir: 'ltr',
      file: 'pl.json',
      country: 'PL',
      currency: 'PLN' as CurrencyCode.PLN,
      flag: 'circle-flags:pl',
    },
    {
      code: 'hu',
      language: 'hu-HU',
      name: 'Hungarian',
      nativeName: 'Magyar',
      dir: 'ltr',
      file: 'hu.json',
      country: 'HU',
      currency: 'HUF' as CurrencyCode.HUF,
      flag: 'circle-flags:hu',
    },
    {
      code: 'ro',
      language: 'ro-RO',
      name: 'Romanian',
      nativeName: 'Română',
      dir: 'ltr',
      file: 'ro.json',
      country: 'RO',
      currency: 'RON' as CurrencyCode.RON,
      flag: 'circle-flags:ro',
    },
    {
      code: 'fr',
      language: 'fr-FR',
      name: 'French',
      nativeName: 'Français',
      dir: 'ltr',
      file: 'fr.json',
      country: 'FR',
      currency: 'EUR' as CurrencyCode.EUR,
      flag: 'circle-flags:fr',
    },
  ],
  lazy: true,
  langDir: 'locales',
  defaultLocale: 'cs',

  strategy: 'prefix',
  trailingSlash: false,

  detectBrowserLanguage: {
    useCookie: true,
    fallbackLocale: 'cs',
    cookieKey: 'locale',
  },
}
