<script setup lang="ts">
import { useOrder } from '~/composables/activeOrder'

const localePath = useLocalePath()
const drawers = useDrawers()
const priceFormatter = usePriceFormatter()
const { t } = useI18n()
const router = useRouter()

const { activeOrder } = useActiveOrder()

async function handleClose() {
  drawers.value.cart.open = false
  await nextTick()
}

async function redirectToCheckout() {
  await handleClose()
  await router.push(localePath('checkout'))
}

const _prizeMoney = usePrizeMoney()

const showCart = computed(() => drawers.value.cart.open)

const { state: _order } = useOrder()
const totalQuantity = computed(() => activeOrder.value?.totalQuantity ?? 0)
const activeOrderLines = computed(() => activeOrder.value?.lines ?? [])
const orderCurrencyCode = computed(() => activeOrder.value?.currency)
</script>

<template>
  <NDrawer id="cart-drawer" slide-from="right" :show="showCart" :title="t('cart.label')" closable @close="handleClose">
    <div class="mt-8" data-testid="cartDrawer">
      <template v-if="totalQuantity">
        <div class="flow-root" data-testid="cartItemsList">
          <ul role="list" class="-my-6 divide-y divide-slate-200">
            <CartLineItem
              v-for="line in activeOrderLines"
              :key="line.id"
              :line="line"
              :currency="orderCurrencyCode!"
              class="px4 py6 sm:px6"
              data-testid="cartLineItem"
              @click:link="handleClose"
            />
          </ul>
        </div>
      </template>

      <div v-else class="h-48 flex items-center justify-center text-xl text-slate-400" data-testid="emptyCartMessage">
        {{ t('cart.empty.heading') }}
      </div>
    </div>

    <template v-if="totalQuantity" #footer>
      <div class="pb-safe">
        <div class="pb6">
          <!-- HU ONLY -->
          <div v-if="locale !== 'hu'" class="bg-amber-200:35 px4 py2 sm:flex sm:items-center sm:px6">
            <NIcon icon="i-ri:timer-flash-line" class="mr3 n-icon text-context -ml1" n="slate8 xl" />
            <span class="text-sm c-slate-800 fw600">{{ t('cart.items_not_reserved') }}</span>
          </div>

          <div class="border-t n-border-base" data-testid="cartSummary">
            <div class="flex justify-between px4 py0.5 pt4 text-base text-slate-900 sm:px6">
              <p class="text-sm" data-testid="freeShippingLabel" v-text="t('product.free_shipping.label')" />
              <div><Icon name="i-ri:check-fill" n="emerald5 lg" class="text-context" /></div>
            </div>

            <div class="flex justify-between px4 py0.5 text-base text-slate-900 sm:px6">
              <p class="text-sm" data-testid="complaintResolutionLabel" v-text="t('order.hassle_free_complaint_resolution')" />
              <div><Icon name="i-ri:check-fill" n="emerald5 lg" class="text-context" /></div>
            </div>

            <div class="flex justify-between px4 py0.5 text-base text-slate-900 sm:px6">
              <p class="text-sm" data-testid="freeGiftLabel" v-text="t('order.free_gift')" />
              <div><Icon name="i-ri:check-fill" n="emerald5 lg" class="text-context" /></div>
            </div>

            <div class="flex justify-between px4 pt2 text-base text-slate-900 fw600 sm:px6">
              <p data-testid="subtotalLabel" v-text="t('order.summary.subtotal.label')" />
              <p v-if="activeOrder.subTotal" data-testid="subtotalAmount">
                {{ priceFormatter(activeOrder.subTotal, activeOrder.currency) }}
              </p>
            </div>
          </div>

          <div class="mt-6 px4 sm:px6">
            <UiButton
              id="checkout"
              size="xl"
              class="w-full justify-center rounded-md fw600 leading-10"
              data-testid="checkoutButton"
              @click="redirectToCheckout"
            >
              {{ t('checkout.label') }}
            </UiButton>
          </div>
        </div>
      </div>
    </template>
  </NDrawer>
</template>
