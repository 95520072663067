export function useCollections() {
  const store = useCollectionsStore()
  const loadedCollectionIds = ref(new Set<string>())
  const intersectionTargets = ref(new Map<string, () => void>())

  // Function to set up intersection observer for a collection
  const observeCollection = (collectionId: string, target: HTMLElement) => {
    const { stop } = useIntersectionObserver(
      target,
      async ([entry]) => {
        if (entry?.isIntersecting && !loadedCollectionIds.value.has(collectionId)) {
          await store.loadChildCollections(collectionId)
          loadedCollectionIds.value.add(collectionId)
        }
      },
      { threshold: 0.1 },
    )

    // Store the cleanup function
    intersectionTargets.value.set(collectionId, stop)
  }

  // Clean up observers when component unmounts
  onUnmounted(() => {
    intersectionTargets.value.forEach(stop => stop())
    intersectionTargets.value.clear()
  })

  // Function to fetch children of multiple collections
  const fetchChildrenOfCollection = async (collectionIds: string[]) => {
    for (const id of collectionIds) {
      if (!loadedCollectionIds.value.has(id)) {
        await store.loadChildCollections(id)
        loadedCollectionIds.value.add(id)
      }
    }
  }

  return {
    ...store,
    observeCollection,
    fetchChildrenOfCollection,
    loadedCollectionIds: readonly(loadedCollectionIds),
  }
}
