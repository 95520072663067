<script setup lang="ts">
const {
  locale,
  t,
} = useI18n()

const route = useRoute()

const _isCheckoutPage = computed(() => ((route?.name as string) ?? '').includes('checkout'))

const days = computed(() => {
  const { 0: monday, 4: friday } = getWeekdays(locale.value, 'short')
  return `${monday} - ${friday}`
})
const hours = computed(() => {
  const { 6: six, 18: eighteen } = getHours(locale.value)
  return `${six} - ${eighteen}`
})
</script>

<template>
  <div class="n-top-bar relative z50 block bg-gray-100 text-gray-500 font-medium hidden lg:block">
    <div
      class="mx-auto h-7 max-w-[1920px] w-full flex items-center justify-between p-inline-5 lg:p-inline-7.5 xl:p-inline-15"
    >
      <span class="inline-flex items-center justify-center font-medium" n="sm">
        <NLink to="/useful-links/contact-us">
          <div class="flex items-center gap-1">
            <NIcon icon="i-mingcute:question-line" class="n-button-icon" />
            <span>{{ t('general.help.label') }}</span>
          </div>
        </NLink>
      </span>

      <span class="inline-flex items-center justify-center font-medium uppercase" n="sm" />

      <span class="inline-flex items-center justify-center font-medium" n="sm">
        <span class="flex gap-1">
          <span class="mr1">
            {{ t('customer_service.days_and_hours_short') }}
          </span>
          <span>
            <NIcon icon="i-mingcute:mail-line" class="n-button-icon" />
            <a href="mailto:support@fashova.com">support@fashova.com</a>
          </span>
        </span>
      </span>
    </div>
  </div>

  <NTinyCarousel :items="4" class="h10 bg-slate-900 text-white lg:hidden">
    <template #slide-0>
      <span class="hfull min-w-0 flex items-center justify-center px2.5 fw600 uppercase" n="xs sm:sm">
        <NIcon icon="i-heroicons:clock" class="-mt-1 n-button-icon" /> {{
          t('customer_service.days_and_hours', {
            hours,
            days,
          })
        }} GST
      </span>
    </template>

    <template #slide-1>
      <span class="hfull min-w-0 flex items-center justify-center px2.5 fw600 uppercase" n="xs sm:sm">
        <NIcon icon="i-heroicons:shield-check" class="-mt-1 n-button-icon" /> {{ t('product.shipping_guarantee.label') }}
      </span>
    </template>

    <template #slide-2>
      <span class="hfull min-w-0 flex items-center justify-center px2.5 fw600 uppercase" n="xs sm:sm">
        <NIcon
          icon="i-heroicons:truck"
          class="-mt-1 n-button-icon"
        /> {{ t('general.sustainability_tab.label_eco_delivery') }}
      </span>
    </template>
    <template #slide-3>
      <span class="hfull min-w-0 flex items-center justify-center px2.5 fw600 uppercase" n="xs sm:sm">
        <NIcon
          icon="i-heroicons:arrow-uturn-left"
          class="-mt-1.5 n-button-icon"
        /> {{ t('policies.convient_return_and_exchange_of_good') }}
      </span>
    </template>
  </NTinyCarousel>

  <LayoutHeader />

  <div class="font-small relative z40 block bg-primary-950 text-white hidden lg:block">
    <div
      class="z0 mx-auto h-10 max-w-[1920px] w-full flex items-center justify-between p-inline-5 lg:p-inline-7.5 xl:p-inline-15"
    >
      <div class="inline-flex flex-1 items-center font-medium uppercase md:justify-left" n="xs">
        <NIcon icon="i-mingcute:time-line" class="-mt-1 n-button-icon" />
        <span class="text-xs">{{ t('general.nav_banner.return_policy_title') }}</span>
      </div>

      <div class="inline-flex flex-1 items-center font-medium uppercase md:justify-center" n="xs">
        <NIcon icon="i-mingcute:truck-line" class="-mt-1 n-button-icon" />
        <span class="text-xs">{{ t('incentives.free_shipping.title') }}</span>
      </div>

      <div class="inline-flex flex-1 items-center font-medium uppercase md:justify-right" n="xs">
        <NIcon icon="i-mingcute:award-line" class="-mt-1 n-button-icon" />
        <span>{{ t('policies.safe_payment') }}</span>
      </div>
      <div class="inline-flex flex-1 items-center font-medium uppercase md:justify-right" n="xs">
        <NIcon icon="i-mingcute:shield-shape-line" class="-mt-1 n-button-icon" />
        <span>{{ t('product.shipping_guarantee.label') }}</span>
      </div>
    </div>
  </div>

  <slot />

  <LazyNavDrawer />
  <LazyCartDrawer />

  <div class="flex-1" />

  <!-- Footer -->
  <LayoutFooter />
</template>
