<script setup lang="ts">
const { t } = useI18n()
const runtimeConfig = useRuntimeConfig()
const companyData = runtimeConfig.public.company
</script>

<template>
  <div data-content-id="cs:cs:footer.md">
    <div class="relative mt-4 flex gap-x-4">
      <div class="h-10 w-10 flex shrink-0 grow-0 items-center justify-center rounded-full bg-slate-100">
        <div class="i-heroicons:map-pin-16-solid n-icon" />
      </div>
      <div class="text-base leading-6">
        <h3 class="mb-3 font-semibold n-text-heading">
          {{ companyData.name }}
        </h3>
        <p class="font-semibold n-text-body">
          {{ t('address.company.label') }}:
        </p>
        <p class="text-slate-500 dark:text-slate-700">
          {{ companyData.streetLine1 }}, {{ companyData.streetLine2 }}, {{ companyData.city }}, {{ t('footer.company_data.uae') }}
        </p>
        <div class="text-slate-500 dark:text-slate-700">
          <p>{{ t('footer.company_id') }} {{ companyData.id }}</p>
        </div>
      </div>
    </div>

    <div class="relative mt-4 flex items-center gap-x-4">
      <div class="h-10 w-10 flex shrink-0 grow-0 items-center justify-center rounded-full bg-slate-100">
        <div class="i-heroicons:envelope-16-solid n-icon" />
      </div>
      <div class="text-base leading-6">
        <p class="font-semibold n-text-body">
          {{ t('footer.customer_support') }} - 6:00 - 18:00 (UTC +2)
        </p>
        <a :href="`mailto:${companyData.emailAddress}`" class="text-slate-500 dark:text-slate-700">
          {{ companyData.emailAddress }}
        </a>
      </div>
    </div>
  </div>
</template>
