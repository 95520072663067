import type { OrderFragment, OrderLineFragment, PaymentMethodQuoteFragment, ProductFragment, ProductOptionFragment, ProductVariantFragment, SearchProductResultFragment, SearchProductVariantResultFragment, SearchQuery, SearchResultFragment, ShippingMethodQuoteFragment } from '#graphql-operations'
import HyperDX from '@hyperdx/browser'
import { flatten } from 'flat'
import { titleCase } from 'scule'

export function useAnalytics() {
  const gtm = useNuxtApp().$gtm

  const state = useStore()
  const route = useRoute()
  const activeChannel = computed(() => state.value.activeChannel)
  const { activeOrder } = useActiveOrder()

  const currency = activeOrder.value?.currency ?? activeChannel.value?.currency

  // Abstraction for GA4 Ecommerce events
  const ecommerce = {
    addPaymentInfo: (paymentMethod?: PaymentMethodQuoteFragment) => {
      if (!paymentMethod)
        return

      if (!activeOrder.value)
        return

      useTrackEvent(`${titleCase('add_payment_info')} - ${currency}`, {
        revenue: {
          amount: formatPrice(activeOrder.value.total),
          currency,
        },
        props: {
          paymentMethod: paymentMethod.code,
          couponCodes: activeOrder.value.couponCodes.join(','),
        },
      })

      const event = {
        event: 'add_payment_info',
        ecommerce: {
          order_id: activeOrder.value.id,
          order_code: activeOrder.value.code,
          currency,
          value: formatPrice(activeOrder.value?.total),
          coupon: '',
          payment_type: paymentMethod.name,
          payment_method_id: paymentMethod.id,
          payment_method_code: paymentMethod.code,
          items: (activeOrder.value?.lines ?? []).map(line => ({
            item_id: line.productVariant.id,
            item_name: line.productVariant.product.name,
            item_variant: variantName(line.productVariant.options),
            discount: 0,
            price: formatPrice(line.productVariant.price),
            quantity: line.quantity,
          })),
        },
      }

      HyperDX.addAction(titleCase(event.event), flatten(event))

      gtm.push({ ecommerce: null })
      gtm.push(event)
    },
    addShippingInfo: (shippingMethod: ShippingMethodQuoteFragment) => {
      if (!activeOrder.value)
        return

      useTrackEvent(`${titleCase('add_shipping_info')} - ${currency}`, {
        revenue: {
          amount: formatPrice(activeOrder.value.total),
          currency,
        },
        props: {
          shippingMethod: shippingMethod.code,
          couponCodes: activeOrder.value.couponCodes.join(','),
        },
      })

      const event = {
        event: 'add_shipping_info',
        ecommerce: {
          order_code: activeOrder.value.code,
          order_id: activeOrder.value.id,
          currency,
          value: formatPrice(activeOrder.value?.total),
          coupon: '',
          shipping_tier: shippingMethod.name,
          shipping_method_id: shippingMethod.id,
          shipping_method_code: shippingMethod.code,
          items: (activeOrder.value?.lines ?? []).map(line => ({
            item_id: line.productVariant.id,
            item_name: line.productVariant.product.name,
            item_variant: variantName(line.productVariant.options),
            discount: 0,
            price: formatPrice(line.productVariant.price),
            quantity: line.quantity,
          })),
        },
      }

      HyperDX.addAction(titleCase(event.event), flatten(event))

      gtm.push({ ecommerce: null })
      gtm.push(event)
    },
    addToCart: (productVariantOrOrderLine: ProductVariantFragment | OrderLineFragment | SearchProductVariantResultFragment, quantity: number) => {
      gtm.push({ ecommerce: null })

      if ('linePrice' in productVariantOrOrderLine) {
        const line = productVariantOrOrderLine

        useTrackEvent(`${titleCase('add_to_cart')} - ${currency}`, {
          revenue: {
            amount: formatPrice(line.unitPrice * quantity),
            currency,
          },
          props: {
            productName: line.productVariant.product.name,
            quantity,
            price: formatPrice(line.unitPrice),
          },
        })

        const event = {
          event: 'add_to_cart',
          ecommerce: {
            order_id: activeOrder.value?.id,
            order_code: activeOrder.value?.code,
            currency,
            value: formatPrice(line.unitPrice * quantity), // quantity takes precedence over line.quantity because the original quantity is updated after the event is fired
            items: [{
              item_id: line.productVariant.id,
              item_name: line.productVariant.product.name,
              item_variant: variantName(line.productVariant.options),
              discount: 0,
              price: formatPrice(line.unitPrice),
              quantity, // quantity takes precedence over line.quantity because the original quantity is updated after the event is fired
            }],
          },
        }

        HyperDX.addAction(titleCase(event.event), flatten(event))
        gtm.push(event)
      }
      else {
        const productVariant = productVariantOrOrderLine

        useTrackEvent(`${titleCase('add_to_cart')} - ${productVariant.currency}`, {
          revenue: {
            amount: formatPrice(productVariant.price * quantity),
            currency: productVariant.currency,
          },
          props: {
            productName: productVariant.name,
            quantity,
            price: formatPrice(productVariant.price),
          },
        })

        const event = {
          event: 'add_to_cart',
          ecommerce: {
            order_id: activeOrder.value?.id,
            order_code: activeOrder.value?.code,
            currency: productVariant.currency,
            value: formatPrice(productVariant.price * quantity),
            items: [{
              item_id: productVariantOrOrderLine.id,
              item_name: productVariantOrOrderLine?.product?.name ?? productVariantOrOrderLine.name,
              item_variant: variantName(productVariantOrOrderLine.options),
              discount: 0,
              price: formatPrice(productVariant.price),
              quantity,
            }],
          },
        }

        HyperDX.addAction(titleCase(event.event), flatten(event))
        gtm.push(event)
      }
    },
    addToWishlist: () => { /* ... */ },
    beginCheckout: () => {
      if (!activeOrder.value)
        return

      useTrackEvent(`${titleCase('begin_checkout')} - ${currency}`, {
        revenue: {
          amount: formatPrice(activeOrder.value.total),
          currency,
        },
        props: {
          couponCodes: activeOrder.value.couponCodes.join(','),
        },
      })

      const event = {
        event: 'begin_checkout',
        ecommerce: {
          order_id: activeOrder.value.id,
          order_code: activeOrder.value.code,
          currency,
          value: formatPrice(activeOrder.value?.total),
          coupon: '',
          items: (activeOrder.value?.lines ?? []).map(line => ({
            item_id: line.productVariant.id,
            item_name: line.productVariant.product.name,
            item_variant: variantName(line.productVariant.options),
            discount: 0,
            price: formatPrice(line.productVariant.price),
            quantity: line.quantity,
          })),
        },
      }

      HyperDX.addAction(titleCase(event.event), flatten(event))

      gtm.push({ ecommerce: null })
      gtm.push(event)
    },
    purchase: (order: OrderFragment) => {
      useTrackEvent(`${titleCase('purchase')} - ${order.currency}`, {
        revenue: {
          amount: formatPrice(order.total),
          currency: order.currency,
        },
        props: {
          orderCode: order.code,
        },
      })

      const event = {
        event: 'purchase',
        ecommerce: {
          order_id: order.id,
          order_code: order.code,
          transaction_id: order.code,
          value: formatPrice(order.total),
          currency: order.currency,
          items: order.lines.map(line => ({
            item_id: line.productVariant.id,
            item_name: line.productVariant.product.name,
            item_variant: variantName(line.productVariant.options),
            discount: 0,
            price: formatPrice(line.productVariant.price),
            quantity: line.quantity,
          })),
        },
      }

      if (order.customer?.emailAddress)
        HyperDX.setGlobalAttributes({ userEmail: order.customer?.emailAddress })

      HyperDX.addAction(titleCase(event.event), flatten(event))

      gtm.push({ ecommerce: null })
      gtm.push(event)
    },
    refund: () => { /* ... */ },
    removeFromCart: (line: OrderLineFragment, quantity: number) => {
      useTrackEvent(`${titleCase('remove_from_cart')} - ${currency}`, {
        revenue: {
          amount: formatPrice(line.unitPrice * quantity),
          currency,
        },
      })

      const event = {
        event: 'remove_from_cart',
        ecommerce: {
          order_id: activeOrder.value?.id,
          order_code: activeOrder.value?.code,
          currency,
          value: formatPrice(line.unitPrice * quantity), // quantity takes precedence over line.quantity because the original quantity is updated after the event is fired
          items: [{
            item_id: line.productVariant.id,
            item_name: line.productVariant.product.name,
            item_variant: variantName(line.productVariant.options),
            discount: 0,
            price: formatPrice(line.unitPrice),
            quantity, // quantity takes precedence over line.quantity because the original quantity is updated after the event is fired
          }],
        },
      }

      HyperDX.addAction(titleCase(event.event), flatten(event))

      gtm.push({ ecommerce: null })
      gtm.push(event)
    },
    selectItem: (item: SearchResultFragment) => {
      const event = {
        event: 'select_item',
        item_list_id: route.name,
        item_list_name: route.meta.title ?? route.name,
        ecommerce: {
          currency,
          items: [{
            item_id: item.id,
            item_name: item.name,
            discount: 0,
            price: formatPrice(item.price),
            quantity: 1,
          }],
        },
      }

      HyperDX.addAction(titleCase(event.event), flatten(event))

      gtm.push({ ecommerce: null })
      gtm.push(event)
    },
    selectPromotion: () => { /* ... */ },
    viewCart: () => {
      if (!activeOrder.value)
        return

      useTrackEvent(`${titleCase('view_cart')} - ${currency}`, {
        revenue: {
          amount: formatPrice(activeOrder.value?.subTotal),
          currency,
        },
      })

      const event = {
        event: 'view_cart',
        ecommerce: {
          order_id: activeOrder.value.id,
          order_code: activeOrder.value.code,
          currency,
          value: formatPrice(activeOrder.value?.subTotal),
          items: (activeOrder.value?.lines ?? []).map(line => ({
            item_id: line.productVariant.id,
            item_name: line.productVariant.product.name,
            item_variant: variantName(line.productVariant.options),
            discount: 0,
            price: formatPrice(line.productVariant.price),
            quantity: line.quantity,
          })),
        },
      }

      HyperDX.addAction(titleCase(event.event), flatten(event))

      gtm.push({ ecommerce: null })
      gtm.push(event)
    },
    viewItem: (productOrVariant: ProductFragment | ProductVariantFragment | SearchProductResultFragment | SearchProductVariantResultFragment) => {
      gtm.push({ ecommerce: null })

      if ('variants' in productOrVariant) {
        const product = productOrVariant
        const variant = product.variants?.[0]
        if (!variant)
          return

        useTrackEvent(`${titleCase('view_item')} - ${variant.currency}`, {
          revenue: {
            amount: formatPrice(variant.price),
            currency: variant.currency,
          },
          props: {
            productId: product.id,
            productName: product.name,
          },
        })

        const event = {
          event: 'view_item',
          ecommerce: {
            currency,
            items: [{
              item_id: product.id,
              item_name: product.name,
              quantity: 1,
              price: formatPrice(variant.price),
            }],
          },
        }

        HyperDX.addAction(titleCase(event.event), flatten(event))

        gtm.push(event)
      }
      else {
        const variant = productOrVariant
        useTrackEvent(`${titleCase('view_item')} - ${variant.currency}`, {
          revenue: {
            amount: formatPrice(variant.price),
            currency: variant.currency,
          },
          props: {
            productId: variant.id,
            productName: variant.name,
          },
        })

        const event = {
          event: 'view_item',
          ecommerce: {
            currency,
            items: [{
              item_id: variant.id,
              item_name: variant.name,
              quantity: 1,
              price: formatPrice(variant.price),
            }],
          },
        }

        HyperDX.addAction(titleCase(event.event), flatten(event))

        gtm.push(event)
      }
    },
    viewItemList: (searchResult?: SearchQuery) => {
      if (searchResult) {
        const event = {
          event: 'view_item_list',
          ecommerce: {
            currency,
            item_list_id: route.name,
            item_list_name: route.meta.title ?? route.name,
            items: (searchResult.search.items ?? []).map((item, index) => ({
              index,
              item_id: item.id,
              item_name: item.name,
              price: formatPrice(item.price),
              quantity: 1,
            })),
          },
        }

        HyperDX.addAction(titleCase(event.event), flatten(event))

        gtm.push({ ecommerce: null })
        gtm.push(event)
      }
    },
    viewPromotion: () => { /* ... */ },
    searchSubmitted: (searchQuery: string) => {
      const event = {
        event: 'search_submitted',
        search_term: searchQuery,
      }

      useTrackEvent(titleCase('search_submitted'), {
        props: {
          searchQuery,
        },
      })

      HyperDX.addAction(titleCase(event.event), flatten(event))
    },
  }

  return {
    ecommerce,
  }
}

const formatPriceCache = new Map<number, number>()
function formatPrice(n?: { min: number, max: number } | { value: number } | number) {
  if (n == null)
    return 0

  const num = typeof n === 'number' ? n : 'min' in n ? n.min : n.value

  if (formatPriceCache.has(num))
    return formatPriceCache.get(num)!

  const formatted = num / 100
  formatPriceCache.set(num, formatted)

  return formatted
}

function variantName(options?: ProductOptionFragment[]) {
  // This is also used by vendure when creating the product variant name
  // https://github.com/vendure-ecommerce/vendure/blob/master/packages/admin-ui/src/lib/catalog/src/providers/product-detail/product-detail.service.ts#L120
  return (options ?? []).map(o => o.name).join(' ').trim()
}
