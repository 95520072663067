<script setup lang="ts">
import type { CollectionListItemFragmentWithChildren } from '~/stores/collections'
import { useOrder } from '~/composables/activeOrder'
import { useRootCollectionsQuery } from '~/stores/collections'

const router = useRouter()
const localePath = useLocalePath()
const drawers = useDrawers()
const { t } = useI18n()
const { ecommerce } = useAnalytics()

const collectionsStore = useCollectionsStore()
const { collectionTree } = storeToRefs(collectionsStore)

const { state: rootCollectionsState } = useRootCollectionsQuery()

// Pick the first 3 collections to show on tabs
const mainCollections = computed(() => rootCollectionsState.value.data?.slice(0, 3))

const currentCollection = ref<CollectionListItemFragmentWithChildren | undefined>(undefined)
const currentRootCollectionId = ref<string>('all')
const searchQueryInput = ref('')

const currentRootCollection = computed(() => collectionTree.value.get(currentRootCollectionId.value))
const showFirstLevelCollectionNavigation = computed(() => !currentCollection.value)

function handleFirstLevelCollectionNavigationClick(collection: CollectionListItemFragmentWithChildren) {
  currentCollection.value = collection
}

function handleCollectionClickBack() {
  currentCollection.value = undefined
}

function onAfterLeave() {
  handleCollectionClickBack()
  if (searchQueryInput.value)
    searchQueryInput.value = ''
  currentRootCollectionId.value = 'all'
}

async function handleSecondLevelCollectionClick(collection: CollectionListItemFragmentWithChildren) {
  handleClose()
  try {
    await router.push(localePath(`/collections/${collection.id}/${collection.slug}`))
  }
  catch (err) {
    console.error(`Navigation to collection ${collection.id} failed`, err)
  }
}

function handleClose() {
  drawers.value.navigation.open = false
}

function openCart() {
  handleClose()
  drawers.value.cart.open = true
}

function _openAccount() {
  handleClose()
  navigateTo(localePath('/account'))
}

async function handleSearchSubmit() {
  let path = localePath('/search')

  if (currentRootCollection.value && currentRootCollectionId.value !== 'all')
    path = localePath(`/collections/${currentRootCollection.value.id}/${currentRootCollection.value.slug}`)

  handleClose()
  ecommerce.searchSubmitted(searchQueryInput.value)
  try {
    await router.push({ path, query: { q: searchQueryInput.value } })
  }
  catch (err) {
    console.error('Search failed', err)
  }
}

const { state: order } = useOrder()
const totalQuantity = computed(() => order.value.data?.totalQuantity ?? 0)
</script>

<template>
  <NDrawer
    id="menu-drawer"
    title="Menu"
    slide-from="left"
    :show="drawers.navigation.open"
    closable
    as="aside"
    @close="handleClose"
    @after-leave="onAfterLeave"
  >
    <template #header>
      <div>
        <header class="sticky top-0 z2 h16 flex shrink-0 grow-0 basis-[14rem] bg-white shadow">
          <div class="hfull flex grow-1 items-center justify-between of-hidden pl4">
            <!-- <NuxtLinkLocale data-testid="link" class="ml2 flex grow-1" to="/account" @click.prevent="openAccount">
              <button
                data-testid="userNav"
                class="h12 flex grow-1 flex-col items-center justify-center rd-1 bg-slate-100 p0"
              >
                <Icon name="i-heroicons:user" class="text-xl" />
                <span data-testid="offCanvasLogin" class="max-w-[15ch] of-hidden text-ellipsis text-xs fw-bold">
                  {{ t('form.sign_in.label') }}
                </span>
              </button>
            </NuxtLinkLocale> -->

            <a data-testid="link" class="ml2 flex grow-1" href="#" @click.prevent="openCart">
              <button
                data-testid="offCanvasToCart"
                class="h12 flex grow-1 flex-col items-center justify-center rd-1 bg-slate-100 p0"
              >
                <span class="flex items-center justify-center">
                  <span class="relative top-0 box-border">
                    <Icon name="i-heroicons:shopping-bag" class="text-xl" />
                  </span>
                  <span v-if="totalQuantity > 0" class="ml0.5 text-2.5 fw700">
                    {{ totalQuantity }}
                  </span>
                </span>
                <span class="max-w-[15ch] of-hidden text-ellipsis text-xs fw-bold">
                  {{ t('cart.label') }}
                </span>
              </button>
            </a>
          </div>

          <button data-testid="drawerCloseIcon" class="flex items-center justify-center p5" @click="handleClose">
            <Icon name="i-heroicons:x-mark" class="text-lg" />
          </button>
        </header>
      </div>
    </template>

    <section class="h-full flex flex-1 flex-col px5 pb2.5">
      <RdxTabsRoot v-model="currentRootCollectionId" class="flex flex-grow flex-col" default-value="all">
        <RdxTabsList
          v-if="showFirstLevelCollectionNavigation"
          class="relative flex shrink-0 border-b n-border-base px5 -mx5"
          aria-label="Collections"
        >
          <RdxTabsIndicator
            class="absolute bottom-0 left-0 h0.75 w-[--radix-tabs-indicator-size] translate-x-[--radix-tabs-indicator-position] rounded-full transition-all duration-150"
          >
            <div class="h-full w-full bg-slate-900" />
          </RdxTabsIndicator>

          <RdxTabsTrigger
            class="mr5 h13 flex cursor-default select-none items-center justify-center rounded-tl-md text-base leading-none outline-none hover:text-slate-500 n-text-body !n-active:text-slate-900 !n-active:fw700"
            value="all"
          >
            {{ t('collections.all') }}
          </RdxTabsTrigger>
          <RdxTabsTrigger
            v-for="collection in mainCollections"
            :key="collection.id"
            class="mr5 h13 flex cursor-default select-none items-center justify-center rounded-tl-md text-base leading-none outline-none hover:text-slate-500 n-text-body !n-active:text-slate-900 !n-active:fw700"
            :value="collection.id"
          >
            {{ collection.name }}
          </RdxTabsTrigger>
        </RdxTabsList>
        <!--        <NuxtLinkLocale to="/search" /> -->

        <form
          v-if="showFirstLevelCollectionNavigation"
          @submit.prevent="handleSearchSubmit"
        >
          <div class="mt4 flex items-center border n-border-base rd bg-slate100 px2 focus-within:n-focus-base focus-within:border-context">
            <Icon name="i-heroicons:magnifying-glass" class="mr-0.4em text-1.1em op50" />
            <input
              v-model="searchQueryInput"
              type="search"
              class="wfull flex-auto bg-transparent py2.5 fw-500 !outline-none"
              :placeholder="t('search.collection_placeholder')"
            >
          </div>
        </form>

        <div class="mt6 -my5" />

        <RdxTabsContent class="relative flex of-hidden" value="all">
          <Transition
            enter-active-class="duration-150 ease-[cubic-bezier(0.81,0.08,0.68,0.53)] top-0"
            enter-from-class="transform op0 translate-x-[-20%] absolute top-0"
            enter-to-class="op100 translate-x-0 top-0"
            leave-active-class="duration-150 ease-[cubic-bezier(0.81,0.08,0.68,0.53)] top-0"
            leave-from-class="op100 translate-x-0 top-0"
            leave-to-class="transform op0 translate-x-[-20%] absolute top-0"
          >
            <div
              v-if="showFirstLevelCollectionNavigation"
              :data-active="showFirstLevelCollectionNavigation"
              class="w-full"
            >
              <nav class="mb2.5 mt6">
                <menu
                  class="pointer-events-auto grid grid-cols-[repeat(1,1fr)] m0 list-none gap1 p0 op100 xs:grid-cols-[repeat(2,1fr)]"
                  data-testid="firstLevelCollectionNavigation"
                >
                  <CollectionTreeNavigationItem
                    v-for="[key, value] in collectionTree" :key="`all-${key}`"
                    :collection="value"
                    prevent
                    @click="handleFirstLevelCollectionNavigationClick"
                  />
                </menu>
              </nav>
            </div>

            <div
              v-else
              :data-active="!showFirstLevelCollectionNavigation"
              class="top-0"
            >
              <nav v-if="!showFirstLevelCollectionNavigation">
                <div class="mr6 flex justify-between py4.5">
                  <button
                    data-testid="collectionNavigationBack"
                    class="cursor-pointer appearance-none border-unset bg-transparent p0"
                    @click="handleCollectionClickBack"
                  >
                    <Icon name="i-ri:arrow-left-s-line" class="block shrink-0 grow-0 text-2xl" />
                  </button>
                  <span class="line-clamp-2 grow-1 text-center text-xl fw-bold">
                    {{ currentCollection?.name }}
                  </span>
                </div>
                <menu data-testid="secondLevelCollectionNavigation" class="pointer-events-auto grid grid-cols-[repeat(1,1fr)] m0 list-none gap1 p0 op100 xs:grid-cols-[repeat(2,1fr)]">
                  <!-- Add a show all collection which shows all, within the current collection -->
                  <template v-if="currentCollection">
                    <CollectionTreeNavigationItem :collection="currentCollection">
                      <span class="line-clamp-2 grow-1 items-center text-ellipsis break-anywhere text-slate-900 fw600 leading-snug hyphens-auto">
                        {{ t('general.show_all') }}
                      </span>
                    </CollectionTreeNavigationItem>

                    <CollectionTreeNavigationItem
                      v-for="[key, value] in currentCollection.children" :key="`all-${key}`"
                      :collection="value"
                      @click="handleSecondLevelCollectionClick"
                    />
                  </template>
                </menu>
              </nav>
            </div>
          </Transition>
        </RdxTabsContent>

        <RdxTabsContent
          v-for="collection in mainCollections"
          :key="collection.id"
          class="relative flex of-hidden"
          :value="collection.id"
        >
          <Transition
            enter-active-class="duration-150 ease-[cubic-bezier(0.81,0.08,0.68,0.53)] top-0"
            enter-from-class="transform op0 translate-x-[-20%] absolute top-0"
            enter-to-class="op100 translate-x-0 top-0"
            leave-active-class="duration-150 ease-[cubic-bezier(0.81,0.08,0.68,0.53)] top-0"
            leave-from-class="op100 translate-x-0 top-0"
            leave-to-class="transform op0 translate-x-[-20%] absolute top-0"
          >
            <div
              v-if="showFirstLevelCollectionNavigation"
              :data-active="showFirstLevelCollectionNavigation"
              class="w-full"
            >
              <!--            :class="showFirstLevelCollectionNavigation ? 'op100 relative wfull translate-x-0' : 'top-0 absolute op0 translate-x-[-20%]'" -->
              <nav class="mb2.5 mt6">
                <menu
                  class="pointer-events-auto grid grid-cols-[repeat(1,1fr)] m0 list-none gap1 p0 op100 xs:grid-cols-[repeat(2,1fr)]"
                  data-testid="firstLevelCollectionNavigation"
                >
                  <template v-if="collectionTree.get(collection.id)?.children.size">
                    <CollectionTreeNavigationItem
                      v-for="[key, value] in collectionTree.get(collection.id)!.children" :key="`${collection.id}-${key}`"
                      :collection="value"
                      prevent
                      @click="handleFirstLevelCollectionNavigationClick"
                    />
                  </template>
                </menu>
              </nav>
            </div>

            <div
              v-else
              :data-active="!showFirstLevelCollectionNavigation"
              class="w-full"
            >
              <nav v-if="!showFirstLevelCollectionNavigation">
                <div class="mr6 flex justify-between py4.5">
                  <button
                    data-testid="collectionNavigationBack"
                    class="cursor-pointer appearance-none border-unset bg-transparent p0"
                    @click="handleCollectionClickBack"
                  >
                    <Icon name="i-ri:arrow-left-s-line" class="block shrink-0 grow-0 text-2xl" />
                  </button>
                  <span class="line-clamp-2 grow-1 text-center text-xl fw-bold">
                    {{ currentCollection?.name }}
                  </span>
                </div>
                <menu data-testid="secondLevelCollectionNavigation" class="pointer-events-auto grid grid-cols-[repeat(1,1fr)] m0 list-none gap1 p0 op100 xs:grid-cols-[repeat(2,1fr)]">
                  <template v-if="currentCollection">
                    <CollectionTreeNavigationItem :collection="currentCollection">
                      <span class="line-clamp-2 grow-1 items-center text-ellipsis break-anywhere text-slate-900 fw600 leading-snug hyphens-auto">
                        {{ t('general.show_all') }}
                      </span>
                    </CollectionTreeNavigationItem>

                    <CollectionTreeNavigationItem
                      v-for="[key, value] in currentCollection.children" :key="`${collection.id}-${key}`"
                      :collection="value"
                      @click="handleSecondLevelCollectionClick"
                    />
                  </template>
                </menu>
              </nav>
            </div>
          </Transition>
        </RdxTabsContent>
      </RdxTabsRoot>

      <div
        v-if="showFirstLevelCollectionNavigation"
        data-testid="actionContainer"
        class="flex justify-between gap1 border-t n-border-base px5 pb3 pt8 -mx5"
      >
        <LanguageCountrySwitch />
      </div>
    </section>
  </NDrawer>
</template>
