import type { CollectionListItemFragment, CollectionListOptions, CollectionsQuery, InputMaybe } from '#graphql-operations'
import { LIST_QUERY_LIMIT } from '~/constants'

export interface CollectionListItemFragmentWithChildren extends CollectionListItemFragment {
  children: Map<string, CollectionListItemFragmentWithChildren>
  isLoading?: boolean
  childrenLoaded?: boolean
}

const sortByPosition = (a: CollectionListItemFragment, b: CollectionListItemFragment) => a.position - b.position

export const useRootCollectionsQuery = defineQuery({
  key: ['collections', 'root'],
  enabled: import.meta.client,
  query: async () => {
    const { data } = await useGraphqlQuery('collections', {
      options: {
        take: LIST_QUERY_LIMIT,
        topLevelOnly: true,
      },
    }) as { data: CollectionsQuery }
    // Assuming the items count of root collections will never exceed LIST_QUERY_LIMIT
    return data.collections.items
  },
})

export const useCollectionsStore = defineStore('collections', () => {
  // state
  const collections = ref<Record<string, CollectionListItemFragment>>({})
  const loadingCollections = ref(new Set<string>())
  const loadedChildren = ref(new Set<string>())

  // initialize with root collections
  const { state: rootCollections } = useRootCollectionsQuery()

  const stop = watch(() => rootCollections.value.data, (data) => {
    if (data?.length) {
      collections.value = {
        ...collections.value,
        ...Object.fromEntries(data.map(item => [item.id, item])),
      }
    }
  }, { immediate: true })

  // getters
  const collectionList = computed(() => Object.values(collections.value).sort(sortByPosition))

  const collectionTree = computed(() => {
    const buildTree = (items: CollectionListItemFragment[]) => {
      const lookup = new Map<string, CollectionListItemFragmentWithChildren>()
      const rootNodes = new Map<string, CollectionListItemFragmentWithChildren>()

      // Initialize lookup Map
      for (const collection of items) {
        lookup.set(collection.id, {
          ...collection,
          children: new Map(),
          isLoading: loadingCollections.value.has(collection.id),
          childrenLoaded: false,
        })
      }

      // Build tree structure
      for (const collection of items) {
        const node = lookup.get(collection.id)!

        if (collection.parent?.name === '__root_collection__') {
          rootNodes.set(collection.id, node)
        }
        else if (collection.parent && lookup.has(collection.parent.id)) {
          const parent = lookup.get(collection.parent.id)!
          if (collection.id !== collection.parent.id) {
            parent.children.set(collection.id, node)
            parent.childrenLoaded = true
          }
        }
      }

      // Sort children recursively
      const sortChildrenByPosition = (node: CollectionListItemFragmentWithChildren) => {
        if (node.children.size > 0) {
          node.children = new Map(
            [...node.children.entries()].sort((a, b) => a[1].position - b[1].position),
          )
          for (const child of node.children.values()) {
            sortChildrenByPosition(child)
          }
        }
      }

      // Sort root nodes and descendants
      const sortedRootNodes = new Map(
        [...rootNodes.entries()].sort((a, b) => a[1].position - b[1].position),
      )
      for (const node of sortedRootNodes.values()) {
        sortChildrenByPosition(node)
      }

      return sortedRootNodes
    }

    return buildTree(collectionList.value)
  })

  // actions
  const fetchCollections = async (options?: InputMaybe<CollectionListOptions>) => {
    const { data } = await useGraphqlQuery('collections', { options }) as { data: CollectionsQuery }
    return data.collections
  }

  const loadChildCollections = async (parentId: string) => {
    if (loadedChildren.value.has(parentId) || loadingCollections.value.has(parentId))
      return

    loadingCollections.value.add(parentId)

    try {
      const result = await fetchCollections({
        take: LIST_QUERY_LIMIT,
        filter: { parentId: { eq: parentId } },
      })

      // Add new collections to state
      collections.value = {
        ...collections.value,
        // Assuming the items count of child collections will never exceed LIST_QUERY_LIMIT
        ...Object.fromEntries(result.items.map(item => [item.id, item])),
      }

      loadedChildren.value.add(parentId)

      return result.items
    }
    finally {
      loadingCollections.value.delete(parentId)
    }
  }

  onUnmounted(() => {
    stop?.()
  })

  return {
    // state
    collections,
    loadingCollections,
    loadedChildren,

    // getters
    collectionList,
    collectionTree,

    // actions
    loadChildCollections,
  }
})
