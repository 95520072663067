import { defineNuxtPlugin, useNuxtApp } from '#app'
import { addRouteMiddleware } from '#app/composables/router'

export default defineNuxtPlugin(() => {
  addRouteMiddleware((to) => {
    const nuxtApp = useNuxtApp()
    if (nuxtApp.ssrContext) {
      nuxtApp.ssrContext.event.context.matchedVueRoute = to.matched[0]
    }
  })
})
