export default {
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
      },
    },
    cs: {
      currency: {
        style: 'currency',
        currency: 'CZK',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      },
    },
    sk: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
      },
    },
    fr: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
      },
    },
    pl: {
      currency: {
        style: 'currency',
        currency: 'PLN',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      },
    },
    hu: {
      currency: {
        style: 'currency',
        currency: 'HUF',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
      },
    },
    ro: {
      currency: {
        style: 'currency',
        currency: 'RON',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
      },
    },
  } as Record<string, Record<string, Intl.NumberFormatOptions>>,
}
