import type { LoginMutationVariables, RegisterCustomerInput, RequestPasswordResetMutationVariables, ResetPasswordMutationVariables, VerifyCustomerAccountMutationVariables } from '#graphql-operations'
import { ErrorCode } from '#graphql-operations'
import { InvalidCredentialsError, MissingPasswordError, NativeAuthStrategyError, NotVerifiedError, PasswordValidationError } from '~/services/errors'

export const useAuth = () => useNuxtApp().$auth

export async function authLogin(body: LoginMutationVariables) {
  const localePath = useLocalePath()
  const { data } = await useGraphqlMutation('login', body)

  if (isGraphQLError(data.login)) {
    switch (data.login.errorCode) {
      case ErrorCode.INVALID_CREDENTIALS_ERROR: {
        throw new InvalidCredentialsError(data.login.message, data.login.authenticationError)
      }
      case ErrorCode.NATIVE_AUTH_STRATEGY_ERROR: {
        throw new NativeAuthStrategyError(data.registerCustomerAccount.message)
      }
      case ErrorCode.NOT_VERIFIED_ERROR: {
        throw new NotVerifiedError(data.login.message)
      }
      default: {
        throw new Error(data.login.message)
      }
    }
  }

  const { redirectTo } = useRoute().query as { redirectTo?: string }
  await useAuth().refreshActiveCustomer()
  await navigateTo(redirectTo || localePath('/'))
}

export async function verifyCustomerAccount(body: VerifyCustomerAccountMutationVariables) {
  const localePath = useLocalePath()
  // NOTE: This acts as authRegister, because email verification is required
  await useGraphqlMutation('verifyCustomerAccount', body)
  await useAuth().refreshActiveCustomer()
  const { redirectTo } = useRoute().query as { redirectTo?: string }
  await navigateTo(redirectTo || localePath('/'))
}

export async function authRegister(input: WithRequired<RegisterCustomerInput, 'password'>) {
  const { data } = await useGraphqlMutation('registerCustomerAccount', { input })

  if (isGraphQLError(data.registerCustomerAccount)) {
    switch (data.registerCustomerAccount.errorCode) {
      case ErrorCode.MISSING_PASSWORD_ERROR: {
        throw new MissingPasswordError(data.registerCustomerAccount.message)
      }
      case ErrorCode.NATIVE_AUTH_STRATEGY_ERROR: {
        throw new NativeAuthStrategyError(data.registerCustomerAccount.message)
      }
      case ErrorCode.PASSWORD_VALIDATION_ERROR: {
        throw new PasswordValidationError(data.registerCustomerAccount.message, data.registerCustomerAccount.validationErrorMessage)
      }
      default: {
        throw new Error(data.registerCustomerAccount.message)
      }
    }
  }
}

export async function authLogout() {
  const store = useStore()
  const { refreshActiveOrder } = useActiveOrder()
  await useGraphqlMutation('logout')
  store.value.activeCustomer = store.value.activeOrder = null
  await useAuth().refreshActiveCustomer()
  await refreshActiveOrder()
}

export async function authForgotPassword(body: RequestPasswordResetMutationVariables) {
  await useGraphqlMutation('requestPasswordReset', body)
}

export async function authResetPassword(body: ResetPasswordMutationVariables) {
  const localePath = useLocalePath()
  await useGraphqlMutation('resetPassword', body)
  const { redirectTo } = useRoute().query as { redirectTo?: string }
  await useAuth().refreshActiveCustomer()
  await navigateTo(redirectTo || localePath('/'))
}
