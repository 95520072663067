const translationImports = new Map([
  [['en'], import('@vee-validate/i18n/dist/locale/en.json')],
  [['cs'], import('@vee-validate/i18n/dist/locale/cs.json')],
  [['sk'], import('@vee-validate/i18n/dist/locale/sk.json')],
  [['pl'], import('@vee-validate/i18n/dist/locale/pl.json')],
  [['hu'], import('@vee-validate/i18n/dist/locale/hu.json')],
  [['ro'], import('@vee-validate/i18n/dist/locale/ro.json')],
  [['fr'], import('@vee-validate/i18n/dist/locale/fr.json')],
  [['es'], import('@vee-validate/i18n/dist/locale/es.json')],
  [['de', 'at'], import('@vee-validate/i18n/dist/locale/de.json')],
  [['it'], import('@vee-validate/i18n/dist/locale/it.json')],
  [['uk'], import('@vee-validate/i18n/dist/locale/uk.json')],
])

// NOTE: Loading of correct locale is currently done by the page reload on locale change

export default defineNuxtPlugin({
  name: 'vee-validate',
  parallel: true,
  async setup(nuxtApp) {
    const { configure, defineRule } = await import('vee-validate')
    const { localize, setLocale } = await import('@vee-validate/i18n')

    const locale = unref((nuxtApp.$i18n as any).locale)
    const entry = Array.from(translationImports.entries()).find(([locales]) => locales.includes(locale))
    const translations = await entry![1].then(module => module.default)
    localize({ [locale]: translations })

    const { all } = await import('@vee-validate/rules')
    Object.entries(all).forEach(([rule, validator]) => defineRule(rule, validator))

    setLocale(locale)

    configure({
      generateMessage: localize({
        [locale]: translations,
      }),
    })
  },
})
