<script setup lang="ts">
import type { CollectionListItemFragmentWithChildren } from '~/composables/collections'

const props = defineProps<{
  collection: CollectionListItemFragmentWithChildren
  prevent?: boolean
}>()

const emit = defineEmits<{
  (event: 'click', collection: CollectionListItemFragmentWithChildren): void
}>()

const target = ref<HTMLElement>()
const { observeCollection } = useCollections()

const localePath = useLocalePath()

function handleClick(event: MouseEvent, collection: CollectionListItemFragmentWithChildren) {
  emit('click', collection)
  if (props.prevent)
    event.preventDefault()
}

onMounted(() => {
  if (target.value)
    observeCollection(props.collection.id, target.value)
})
</script>

<template>
  <li
    ref="target"
    :data-testid="`collectionTreeNavigationItem_${collection.name}`"
  >
    <a
      data-testid="link"
      :href="localePath(`/collections/${collection.id}/${collection.slug}`)"
      @click="(event: MouseEvent) => handleClick(event, collection)"
    >
      <div class="relative flex items-center justify-between gap-2 of-hidden rd bg-slate-100 px4 py3">
        <slot>
          <span class="line-clamp-2 grow-1 items-center text-ellipsis break-anywhere text-slate-900 fw700 leading-snug hyphens-auto">
            {{ props.collection.name }}
          </span>
        </slot>
        <div class="h11 min-w-[2rem] flex shrink-0 items-center justify-center">
          <div
            data-testid="productImage"
            class="relative h0 w-full of-hidden rd-0.5 bg-slate-100 pt-[100%] [&>img]:absolute [&>img]:inset-[5%] [&>img]:m-auto [&>img]:aspect-3/4 [&>img]:hfull [&>img]:max-h-[90%] [&>img]:max-w-[90%] [&>img]:wfull [&>img]:rd [&>img]:object-contain [&>img]:object-center [&>img]:align-bottom"
          >
            <NuxtImg
              height="100"
              width="100"
              decoding="async"
              loading="lazy"
              :src="collection.featuredAsset?.preview"
              :alt="collection.name"
              data-testid="collectionImageView"
            />
          </div>
        </div>
      </div>
    </a>
  </li>
</template>
