<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'
import { useOrder } from '~/composables/activeOrder'
import { useRootCollectionsQuery } from '~/stores/collections'

import { mapSlice } from '~/utils/map'

const { ecommerce } = useAnalytics()
const router = useRouter()
const route = useRoute()
const { el: headerRef, isHeaderVisible } = useUiHeader()
const isSearchPage = computed(() => ((route?.name as string) ?? '').includes('search'))
const isCheckoutPage = computed(() => ((route?.name as string) ?? '').includes('checkout'))
const drawers = useDrawers()
const { t } = useI18n()
const localePath = useLocalePath()
const { navigation: _accountNav } = useAccount()

const collectionsStore = useCollectionsStore()
const { state: rootCollectionsState } = useRootCollectionsQuery()

const { collectionTree } = storeToRefs(collectionsStore)

const loggedIn = computed(() => useAuth().loggedIn.value === true)

const searchQuery = useRouteQuery('q', '')
const searchQueryInput = ref(searchQuery.value)

watch(isSearchPage, (isSearchPage) => {
  if (!isSearchPage)
    searchQueryInput.value = ''
})

const openDropdownMenu = ref(false)
function _onDropdownMenuOpen(value: boolean) {
  if (!loggedIn.value)
    return router.push(localePath('/account'))
  openDropdownMenu.value = value
}

function handleNavigationClick() {
  drawers.value.navigation.open = true
}

async function handleCartClick() {
  drawers.value.cart.open = true
  ecommerce.viewCart()
}

function submitSearch(event: Event) {
  event.preventDefault()
  ecommerce.searchSubmitted(searchQueryInput.value)
  router.push({
    path: localePath('/search'),
    query: { q: searchQueryInput.value },
  })
}

// Pick the first 3 collections to show on tabs
const mainCollections = computed(() => rootCollectionsState.value.data?.slice(0, 3))
const currentRootCollectionId = ref(mainCollections.value?.[0]?.id)
const currentRootCollection = computed(() => currentRootCollectionId.value ? collectionTree.value.get(currentRootCollectionId.value) : null)

watch(mainCollections, (collections) => {
  if (!currentRootCollectionId.value && collections?.[0]?.id)
    currentRootCollectionId.value = collections[0].id
})

watch(currentRootCollectionId, async (collectionId) => {
  if (collectionId)
    await collectionsStore.loadChildCollections(collectionId)
})

function onMainCollectionClick(collectionId: string) {
  currentRootCollectionId.value = collectionId
}

const { state: order } = useOrder()
const totalQuantity = computed(() => order.value.data?.totalQuantity ?? 0)
</script>

<template>
  <Header ref="headerRef" :visible="isHeaderVisible">
    <template #logo>
      <div class="my-auto ml0 mr-auto">
        <NuxtLinkLocale
          to="/" class="ml2 mr3 w20 flex overflow-hidden md:w-auto xs:w-[8.75rem]"
          :aria-label="t('general.homepage.label')"
        >
          <FashovaLogo class="h9 w-auto text-slate-900 dark:text-white" />
        </NuxtLinkLocale>
      </div>
    </template>

    <template #center>
      <div class="ml4 text-gray-800 -m-l-2 lg:hidden">
        <UiButton
          v-if="!isCheckoutPage"
          id="navigation"
          :aria-label="t('general.navigation.label')"
          variant="ghost"
          size="icon"
          @click="handleNavigationClick"
        >
          <Icon name="i-fluent:navigation-24-regular" class="text-xl" />
        </UiButton>
      </div>

      <div
        v-if="!isCheckoutPage"
        data-testid="genderSwitch"
        class="mx2 hfull text-gray-800 hidden -m-l-2 lg:inline-flex lg:items-center"
      >
        <div
          v-for="(collection, index) in mainCollections"
          :key="collection.id"
          :data-testid="`genderSwitch-${index}`"
          :data-active="collection.id === currentRootCollectionId"
          class="relative hfull cursor-pointer px2 text-slate-400 fw600 leading-16 transition-colors duration-500 ease after:absolute after:bottom-0 after:left-0 after:right-0 after:m-auto after:h0.5 after:w6 after:scale-0 after:bg-black after:transition-transform after:duration-500 after:ease after:content-[''] n-active:text-slate-900 n-active:after:scale-100"
          @click="onMainCollectionClick(collection.id)"
        >
          {{ collection.name }}
        </div>
      </div>
    </template>

    <template #right>
      <div v-if="!isCheckoutPage" class="hidden lg:block">
        <LanguageButton />
      </div>

      <UiButton :aria-label="t('general.search.label')" size="icon" variant="ghost" class="lg:hidden" @click="handleNavigationClick">
        <Icon name="i-mingcute:search-2-line" class="text-xl" />
      </UiButton>

      <div class="relative flex items-center">
        <UiButton id="cart" :aria-label="t('cart.label')" size="icon" variant="ghost" data-testid="cartButton" @click="handleCartClick">
          <Icon name="i-mingcute:shopping-cart-1-line" class="text-xl" />
        </UiButton>

        <ClientOnly>
          <span
            v-if="totalQuantity > 0"
            class="pointer-events-none absolute h6 w6 flex items-center justify-center rounded-full bg-primary-950 text-sm text-white -right-2 -top-1"
          >
            {{ totalQuantity }}
          </span>
        </ClientOnly>
      </div>
    </template>

    <template v-if="!isCheckoutPage" #navbar>
      <div class="flex flex-row items-center gap-x-2 lg:-m-x-1">
        <div class="flex grow content-center items-center of-hidden lg:ml1 lg:flex lg:py-2">
          <template v-if="currentRootCollection?.children.size">
            <NuxtLinkLocale
              v-for="[,collection] in mapSlice(currentRootCollection.children, 0, 13)"
              :key="collection.id"
              :to="`/collections/${collection.id}/${collection.slug}`"
              n="sm slate9 dark:slate2"
              class="mr8 whitespace-nowrap text-context fw-600 n-transition"
            >
              {{ collection.name }}
            </NuxtLinkLocale>
          </template>
          <template v-else>
            <NSkeleton v-for="i in 9" :key="i" class="mr8" :style="{ width: `${100 + (i * i + i) % 7 * 20}px` }" pulse />
          </template>
        </div>
        <form class="mx2" @submit.prevent="submitSearch">
          <label for="searchbar" class="sr-only">{{ t('general.search.label') }}</label>
          <div class="relative w-full items-center">
            <UiInput id="searchbar" v-model="searchQueryInput" :placeholder="t('general.search_products.label')" class="[*]:rd-md mb2 block border-0 rd-md bg-gray-1 pl10 active:border-0 focus:border-0 hover:border-0" />
            <span class="absolute start-0 inset-y-0 flex items-center justify-center px2">
              <Icon name="i-fluent:search-24-regular" />
            </span>
          </div>
        </form>
      </div>
    </template>

    <!-- <div class="w-full">
      <form @submit.prevent="submitSearch">
        <label for="search" class="sr-only">{{ t('general.search.label') }}</label>
        <div class="relative w-full items-center">
          <UiInput id="search" v-model="searchQueryInput" class="pl10" />
          <span class="absolute start-0 inset-y-0 flex items-center justify-center px2">
            <Icon name="i-heroicons:magnifying-glass" />
          </span>
        </div>
      </form>
    </div> -->
  </Header>
</template>
