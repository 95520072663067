<script setup lang="ts">
import { UiButton } from '#components'
import { upperFirst } from 'scule'
import { i18n } from '~/config/i18n'

const { t, locale, locales, setLocale } = useI18n()

const switchLocalePath = useSwitchLocalePath()

const availableLocales = computed(() => locales.value.map((l: any) => {
  const formattedNumber = new Intl.NumberFormat(l.language, {
    style: 'currency',
    currency: l.currency,
  }).format(0)

  const localCurrencySymbol = formattedNumber.replace(/[\d.,\s]/g, '')

  return {
    ...l,
    label: `${l.country} (${localCurrencySymbol})`,
    nativeName: upperFirst(l.nativeName),
  }
}))

const selectedLocale = ref(locale.value)
const dropdownPosition = ref({ top: 0, left: 0 })
const localeFlagMap = i18n.locales.reduce((acc, cur) => {
  acc[cur.code] = cur.flag
  return acc
}, {} as Record<string, string>)

async function handleClick(locale: string) {
  await setLocale(locale)
  switchLocalePath(locale)
  window.location.reload()
}

// Position dropdown relative to button
function setDropdownPosition() {
  const button = document.getElementById('language-button')
  if (button) {
    const rect = button.getBoundingClientRect()
    dropdownPosition.value = {
      top: rect.bottom + window.scrollY, // Adjust for scroll position
      left: rect.left + window.scrollX,
    }
  }
}

onMounted(() => {
  setDropdownPosition()
  window.addEventListener('resize', setDropdownPosition) // Recalculate on window resize
})
</script>

<template>
  <HMenu as="div" class="relative z-50 inline-block">
    <div>
      <HMenuButton
        id="language-button"
        :aria-label="t('general.language_and_currency.label')"
        :as="UiButton"
        variant="ghost"
        class="fw600"
        @click="setDropdownPosition"
      >
        <Icon :name="localeFlagMap[selectedLocale]" alt="flag" class="mr-1 rd-full ring-0.5 ring-primary/25" />
        {{ availableLocales.find((l) => l.code === selectedLocale)?.label }}
      </HMenuButton>
    </div>

    <Teleport to="body">
      <Transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 op0"
        enter-to-class="transform scale-100 op100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 op100"
        leave-to-class="transform scale-95 op0"
      >
        <HMenuItems
          v-if="dropdownPosition.top && dropdownPosition.left"
          :style="{ position: 'fixed', top: `${dropdownPosition.top}px`, left: `${dropdownPosition.left}px`, zIndex: 100 }"
          class="absolute right-0 z-50 min-w-32 w-56 of-hidden border rounded-md bg-popover p1 text-popover-foreground shadow-md"
          :side-offset="5"
        >
          <div v-for="loc in availableLocales" :key="loc.code" class="p1">
            <HMenuItem class="cursor-pointer" @click="handleClick(loc.code)">
              <div class="relative flex cursor-default select-none items-center gap-2 rounded-sm px-2 py-1.5 text-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50 [&>svg]:size-4 [&>svg]:shrink-0 hover:bg-accent hover:text-accent-foreground">
                <Icon :name="localeFlagMap[loc.code]" alt="flag" class="mr1 rd-full ring-0.5 ring-primary/25" />
                {{ loc.label }}
              </div>
            </HMenuItem>
          </div>
        </HMenuItems>
      </Transition>
    </Teleport>
  </HMenu>
</template>
